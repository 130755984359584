import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ruRU } from "@mui/x-data-grid";
import { getRecordings, deleteRecording } from "../../services/recordingService";
import { formatDate } from "../../modules/formater";
import Header from "../../components/Header";
import GridButtonToolbar from "../../toolbars/GridButtonToolbar";
import UserContext from "../../contexts/userContext";
import {apiConfig, titleConfig} from "../../data/config";

const Recordings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [filterModel, setFilterModel] = useState(null);
  const { showAlert } = useContext(UserContext);

  useEffect(() => {
    document.title = titleConfig.RECORDINGS;  // Устанавливаем заголовок страницы
  }, []);

  const updateRecordings = async () => {
    setLoading(true);
    try {
      let search;
      if (filterModel?.quickFilterValues?.length > 0) {
        search = filterModel?.quickFilterValues.join(" ");
      } else {
        search = "";
      }
      const data = await getRecordings(page, pageSize, search);
      setRecordings(data.recordings);
      setTotalCount(data.totalCount);
    } catch (error) {
      showAlert("Ошибка при получении записей: " + error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchRecordings = async () => {
      setLoading(true);
      try {
        let search;
        if (filterModel?.quickFilterValues?.length > 0) {
          search = filterModel?.quickFilterValues.join(" ");
        } else {
          search = "";
        }

        const data = await getRecordings(page, pageSize, search);
        setRecordings(data.recordings);
        setTotalCount(data.totalCount);
      } catch (error) {
        showAlert("Ошибка при получении записей: " + error.message, "error");
      } finally {
        setLoading(false);
      }
    };

    fetchRecordings();
  }, [page, pageSize, filterModel]);

  const handleDelete = async () => {
    if (selectedRecords.length > 0) {
      try {
        await Promise.all(selectedRecords.map(async (id) => await deleteRecording(id)));
        await updateRecordings();
        setSelectedRecords([]);
        showAlert("Записи успешно удалены", "success");
      } catch (error) {
        showAlert("Ошибка при удалении записей: " + error.message, "error");
      }
    }
  };

  const columns = [
    { field: "id", headerName: "Id", width: 50, sortable: false },
    { field: "title", headerName: "Название", cellClassName: "name-column--cell", width: 200, sortable: false },
    { field: "description", headerName: "Описание", width: 250, sortable: false },
    { field: "userFullName", headerName: "ID пользователя", width: 100, sortable: false },
    { field: "leadFullName", headerName: "ID лидера", width: 100, sortable: false },
    {
      field: "recordingUrl",
      headerName: "Запись",
      sortable: false,
      width: 300,
      renderCell: (params) => (
        <audio controls>
          <source src={`${apiConfig.MUSIC_BASE_URL}${params.value}`} type="audio/mpeg" loading="lazy" />
          Ваш браузер не поддерживает аудио.
        </audio>
      ),
    },
    {
      field: "createdAt",
      headerName: "Дата создания",
      sortable: false,
      width: 150,
      renderCell: (params) => formatDate(params.value),
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Записи разговоров" subtitle="Список записей разговоров персонала" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
          "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
        }}
      >
        <DataGrid
          disableColumnSorting
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          checkboxSelection
          rows={recordings}
          columns={columns}
          loading={loading}
          pagination
          paginationMode="server"
          rowCount={totalCount}
          page={page}
          pageSize={pageSize}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          filterMode="server"
          onFilterModelChange={(model) => setFilterModel(model)}
          components={{
            Toolbar: () => (
              <GridButtonToolbar
                onDelete={handleDelete}
                selectedCount={selectedRecords.length}
                no_create={true}
                no_edit={true}
              />
            ),
          }}
          onSelectionModelChange={(newSelection) => setSelectedRecords(newSelection)}
        />
      </Box>
    </Box>
  );
};

export default Recordings;
