import authAxios from "./authAxios";

// Функция для получения ивентов
export const fetchEvents = async () => {
    try {
      const response = await authAxios.get('/events')
      
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to fetch events");
      }
    } catch (error) {
      console.error(`Error fetching events:, ${error}`);
      throw error;
    }
  };

  export const createEvent = async (title, start_time, description) => {
    try {
      const response = await authAxios.post(
        "/events", 
        {
          description: description,
          event_start_time: start_time,
          is_expired: false,
          title: title
        })
      if (response.status === 201) {
        return response.data
      } else {
        throw new Error(`Error creating event`)
      }
      
    } catch (error) {
      console.error(`Error creating event: ${error}`)
      throw error
    }
  }

  export const deleteEvent = async (eventId) => {
    try {
      const response = await authAxios.delete(`/events/${eventId}`);
      if (response.status === 200) {
        return true; // Успешное удаление
      }
      throw new Error("Не удалось удалить событие");
    } catch (error) {
      console.error(`Ошибка при удалении события: ${error}`);
      throw error;
    }
  };