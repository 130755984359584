import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
const ProgressCircle = ({progress = "0.70", size="40"}) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const angle = progress * 360;
  return (
    <Box 
    sx={{
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
        conic-gradient(transparent 0deg ${angle}deg, ${colors.blueAccent[500]} ${angle}deg 360deg),
        ${colors.greenAccent[500]}`,
      borderRadius: "50%",
      width: `${size}px`,
      height: `${size}px`,
    }}
     >
      <Typography
        variant="h6"
        sx={{
          color: colors.blueAccent[500],
          fontWeight: "bold",
          position: "absolute",
          fontSize: `${size * 0.3}px`, // Размер шрифта пропорционально размеру круга
        }}
      >
        {`${Math.round(progress * 100)}%`}
      </Typography>
    </Box>
  );
};

export default ProgressCircle;