import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequest, logoutRequest } from '../services/authService';
import { getMe } from '../services/userService';
import { setAuthFunctions } from '../services/authAxios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Загрузка состояния сессии
  const navigate = useNavigate();

  const navigateToLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const updateAuthStatus = (status) => {
    setIsAuthenticated(status);
  };

  useEffect(() => {

    const checkSession = async () => {
      setLoading(true);
      try {
        const me = await getMe();
        if (me) {
          setUser(me);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          navigateToLogin()
        }
      } catch (error) {
        setIsAuthenticated(false);
        navigateToLogin()
      } finally {
        setLoading(false);
      }
    };


    checkSession();

  }, [navigateToLogin]);

  setAuthFunctions(navigateToLogin, updateAuthStatus);

  const login = async (username, password) => {
    try {
      const data = await loginRequest(username, password);
      const userData = {
        username: data.username,
        user_id: data.id,
        role: data.role,
        fullName: data.full_name,
      };

      localStorage.setItem("user", JSON.stringify(userData));
      setUser(userData);
      setIsAuthenticated(true);
      navigate('/');
    } catch (error) {
      if (error.response.data.message) {
        return error.response.data.message
      }
      console.error('Ошибка авторизации:', error);
    }
  };

  const logout = async () => {
    try {
      const status = await logoutRequest();
      if (status === 200) {
        setIsAuthenticated(false);
        setUser(null);
        navigate('/login');
      }
    } catch (error) {
      console.error("Ошибка выхода из системы:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, isAuthenticated, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
