import React, { useEffect, useState, useContext } from "react";
import { Box, useTheme, TextField, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ruRU } from "@mui/x-data-grid";
import Header from "../../components/Header";
import LeadsButtonToolbar from "../../toolbars/LeadsButtonToolbar";
import { changeLeadsUser, changeLeadsStatus, deleteLeadsList, getLeads, downloadLeads } from "../../services/leadsService";
import { getUsers } from "../../services/adminService";
import { getStatuses } from "../../services/statusService";
import { getBases } from "../../services/baseService";
import { AuthContext } from "../../contexts/authContext"; 
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import UserContext from "../../contexts/userContext";
import UploadModal from "../../modals/UploadModal";
import StatusesFilter from "../../components/StatusesFilter";
import BasesFilter from "../../components/BasesFilter";
import UsersFilter from "../../components/UsersFilter";
import { useSearchParams, useNavigate } from "react-router-dom"
import { titleConfig } from "../../data/config";

const Leads = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(AuthContext);
  const [leads, setLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [pageJump, setPageJump] = useState(page + 1);
  const { showAlert } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateCreatedStart, setDateCreatedStart] = useState(null);
  const [dateCreatedEnd, setDateCreatedEnd] = useState(null);
  const [dateUpdatedStart, setDateUpdatedStart] = useState(null);
  const [dateUpdatedEnd, setDateUpdatedEnd] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [filterModel, setFilterModel] = useState({});
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [me, setMe] = useState(null);

  useEffect(() => {
    document.title = titleConfig.LEADS;  // Устанавливаем заголовок страницы
  }, []);
  




  useEffect(() => {
    if (user) {
      setMe(user);
    }
  }, [user]);


  const parseFiltersFromParams = () => {
    
    
    const selectedStatusParams = searchParams.get("statuses")
      ? searchParams.get("statuses").split(",").map((id) => {
          const status = statuses.find((s) => String(s.id) === id);
          return { id: status?.id, title: status?.title };
        })
      : [];

    setSelectedStatuses(selectedStatusParams);

    const selectedUsersParams = searchParams.get("users")
      ? searchParams.get("users").split(",").map((id) => {
          const user = users.find((u) => String(u.id) === id);
          return { id: user?.id, full_name: user?.full_name };
        })
      : [];

    setSelectedUsers(selectedUsersParams);

    const selectedDatabaseParams = searchParams.get("databases")
      ? searchParams.get("databases").split(",").map((id) => {
          const database = databases.find((db) => String(db.id) === id);
          return { id: database?.id, title: database?.title };
        })
      : [];

    setSelectedDatabase(selectedDatabaseParams);


    

    setDateCreatedStart(
      searchParams.get("dateCreatedStart")
        ? moment(searchParams.get("dateCreatedStart"))
        : null
    );
    setDateCreatedEnd(
      searchParams.get("dateCreatedEnd")
        ? moment(searchParams.get("dateCreatedEnd"))
        : null
    );
    setDateUpdatedStart(
      searchParams.get("dateUpdatedStart")
        ? moment(searchParams.get("dateUpdatedStart"))
        : null
    );
    setDateUpdatedEnd(
      searchParams.get("dateUpdatedEnd")
        ? moment(searchParams.get("dateUpdatedEnd"))
        : null
    );

    const pageParam = searchParams.get("page");
    setPage(pageParam ? parseInt(pageParam, 10) - 1 : 0);

    const sortParam = searchParams.get("sort");
    if (sortParam) {
      const [field, direction] = sortParam.split(":");
      setSortModel([{ field, sort: direction.toLowerCase() }]);
    }
  };

  const applyFiltersToParams = () => {
    const params = { ...Object.fromEntries(searchParams.entries()) };

    if (selectedStatuses.length > 0) {
      params.statuses = selectedStatuses.map((status) => status.id).join(",");
    } else {
      delete params.statuses;
    }
    if (selectedUsers.length > 0) {
      params.users = selectedUsers.map((user) => user.id).join(",");
    } else {
      delete params.users;
    }
    if (selectedDatabase.length > 0) {
      params.databases = selectedDatabase.map((database) => database.id).join(",");
    } else {
      delete params.databases;
    }
    if (dateCreatedStart) {
      params.dateCreatedStart = moment(dateCreatedStart).format("YYYY-MM-DD");
    } else {
      delete params.dateCreatedStart;
    }
    if (dateCreatedEnd) {
      params.dateCreatedEnd = moment(dateCreatedEnd).format("YYYY-MM-DD");
    } else {
      delete params.dateCreatedEnd;
    }
    if (dateUpdatedStart) {
      params.dateUpdatedStart = moment(dateUpdatedStart).format("YYYY-MM-DD");
    } else {
      delete params.dateUpdatedStart;
    }
    if (dateUpdatedEnd) {
      params.dateUpdatedEnd = moment(dateUpdatedEnd).format("YYYY-MM-DD");
    } else {
      delete params.dateUpdatedEnd;
    }

    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortDirection = sortModel[0].sort.toUpperCase();
      params.sort = `${sortField}:${sortDirection}`;
    } else {
      delete params.sort;
    }
    params.page = page + 1; // Добавляем текущую страницу (для пользователя она начинается с 1)
    setSearchParams(params);

    setSearchParams(params);
  };

  const onSortModelChange = (newSortModel) => {
  if (newSortModel && newSortModel.length > 0) {
    const sortField = newSortModel[0]?.field || "";
    const sortDirection = newSortModel[0]?.sort?.toUpperCase() || "ASC";
    const params = { ...Object.fromEntries(searchParams.entries()) };
    params.sort = `${sortField}:${sortDirection}`;
    setSortModel(newSortModel);
    setSearchParams(params);
  } else {
    // Если сортировка отключена, удаляем параметр сортировки
    setSortModel([]);
    const params = { ...Object.fromEntries(searchParams.entries()) };
    delete params.sort;
    setSearchParams(params);
  }
};

  const updateLeads = async () => {
    setLoading(true);
    try {
      // Формируем поисковую строку
      let search = "";
      if (filterModel?.quickFilterValues?.length > 0) {
        search = filterModel.quickFilterValues.join(" ");
      }
      const sort = searchParams.get("sort");
      let orderBy;
      if (sort) {
        const [field, direction] = sort.split(":");
        orderBy = `${field}:${direction.toUpperCase()}`;
      }
      // Подготавливаем другие параметры
      const selectedStatusesString = searchParams.get("statuses");
      const selectedUsersString = searchParams.get("users");
      const selectedBases = searchParams.get("databases");
      const createdFrom = searchParams.get("dateCreatedStart");
      const createdTo = searchParams.get("dateCreatedEnd");
      const updatedFrom = searchParams.get("dateUpdatedStart");
      const updatedTo = searchParams.get("dateUpdatedEnd");
      const currentPage = searchParams.get("page") 
      ? parseInt(searchParams.get("page"), 10)
      : page;
      // Запрос данных с сервером
      const data = await getLeads(
        currentPage,
        pageSize,
        search,
        selectedBases,
        selectedUsersString,
        selectedStatusesString,
        createdFrom,
        createdTo,
        updatedFrom,
        updatedTo,
        orderBy
      );
  
      // Обновляем состояния
      setLeads(data.leads);
      setTotalCount(data.totalCount);
    } finally {
      setLoading(false);
    }
  };


  

  const fetchAllData = async (fetchFunction) => {
    let page = 0;
    const pageSize = 100; 
    let allData = [];
    let totalCount = Infinity;
  
    while (allData.length < totalCount) {
      const { leadsDatabases, totalCount: count } = await fetchFunction(
        page,
        pageSize,
        ""
      );
      allData = [...allData, ...leadsDatabases];
      totalCount = count;
      page++;
    }
  
    return allData;
  };

  useEffect(() => {
    if (isDataLoaded) {
      parseFiltersFromParams();
      updateLeads();
    }
  }, [isDataLoaded, searchParams, filterModel]);

  const handleApplyFilters = () => {
    applyFiltersToParams();
  };

  const handleResetFilters = () => {
    setFilterModel({});
    setSelectedStatuses([]);
    setSelectedUsers([]);
    setSelectedDatabase([]);
    setDateCreatedStart(null);
    setDateCreatedEnd(null);
    setDateUpdatedStart(null);
    setDateUpdatedEnd(null);
    setSearchParams({});
    updateLeads();
  };

  const handleExport = async () => {
    try {
        // Собираем параметры из фильтров или выбранных записей
        const params = {};

        if (selectedLeads.length > 0) {

            params.leadIds = selectedLeads.join(",");
        } else {
            params.search = filterModel?.quickFilterValues?.join(" ") || "";
            params.statusIds = selectedStatuses.map((status) => status.id).join(",");
            params.userIds = selectedUsers.map((user) => user.id).join(",");
            params.databaseIds = selectedDatabase.map((database) => database.id).join(",");
            params.dateCreatedStart = dateCreatedStart ? moment(dateCreatedStart).format("YYYY-MM-DD") : undefined;
            params.dateCreatedEnd = dateCreatedEnd ? moment(dateCreatedEnd).format("YYYY-MM-DD") : undefined;
            params.dateUpdatedStart = dateUpdatedStart ? moment(dateUpdatedStart).format("YYYY-MM-DD") : undefined;
            params.dateUpdatedEnd = dateUpdatedEnd ? moment(dateUpdatedEnd).format("YYYY-MM-DD") : undefined;

            if (sortModel.length > 0) {
                const { field, sort } = sortModel[0];
                params.orderBy = `${field}:${sort.toUpperCase()}`;
            }
        }

        // Выполняем скачивание файла
        const response = await downloadLeads(params);

        // Выводим сообщение об успехе
        showAlert("Данные успешно экспортированы", "success");
    } catch (error) {
        console.error("Ошибка экспорта данных:", error);
        showAlert("Ошибка экспорта данных: " + error.message, "error");
    }
};



  const fetchAdditionalData = async () => {
    const statuses = await getStatuses();
    setStatuses(statuses);
    
    const allBases = await fetchAllData(getBases);
    setDatabases(allBases);
  
    if (user?.role === "admin" || user?.role === "teamlead") {
      const usersData = await getUsers();
      setUsers(usersData);
    }
  
    setIsDataLoaded(true); // Отмечаем завершение загрузки данных
  };

  useEffect(() => {
    fetchAdditionalData();
  }, []);

  const onFilterChange = (model) => {
    setFilterModel(model);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    const params = { ...Object.fromEntries(searchParams.entries()) };
    params.page = newPage + 1; // Для пользователя страницы начинаются с 1
    setSearchParams(params);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    const params = { ...Object.fromEntries(searchParams.entries()) };
    params.pageSize = newPageSize;
    setSearchParams(params);
  };

  const handlePickChange = async (user_id) => {
    if (selectedLeads.length > 0) {
      try {
        await changeLeadsUser(selectedLeads, user_id);
        await updateLeads();
        setSelectedLeads([]);
        showAlert("Лиды успешно прикреплены", "success");
      } catch (error) {
        showAlert("Ошибка при прикреплении лидов: " + error.message, "error");
      }
    }
  };

  const handlePageJump = () => {
    if (pageJump > 0 && pageJump <= Math.ceil(totalCount / pageSize)) {
      const newPage = pageJump; // Преобразуем в индекс страницы
      setPage(newPage - 1);
      const params = { ...Object.fromEntries(searchParams.entries()) };
      params.page = pageJump - 1;
      setSearchParams(params);
    } else {
      setPageJump(""); // Сбросить поле на пустое значение
    }
  };

  const handlePageJumpChange = (e) => {
    const value = e.target.value;
    
    if (value === "" || /^[1-9]\d*$/.test(value)) {
      setPageJump(value); // Разрешить ввод только чисел, начиная с 1
    }
    
    // Если введен 0 или отрицательное число, подсветить поле
    if (value === "0" || parseInt(value) < 1) {
      e.target.setCustomValidity("Неверный номер страницы");
    } else {
      e.target.setCustomValidity(""); // Убираем ошибку
    }
  };

  const handleChangeStatus = async (status_id) => {
    if (selectedLeads.length > 0) {
      try {
        await changeLeadsStatus(selectedLeads, status_id);
        await updateLeads();
        showAlert("Статус лидов успешно изменен", "success");
        setSelectedLeads([]);
      } catch (error) {
        showAlert("Ошибка при изменении статуса: " + error.message, "error");
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (selectedLeads.length === 0) {
        showAlert("Пожалуйста, выберите хотя бы один лид для удаления", "warning");
        return;
      }
  
      const response = await deleteLeadsList(selectedLeads);
  
      if (response === 200) {
        await updateLeads();
        setSelectedLeads([]);
        showAlert("Лиды успешно удалены", "success");
      }
    } catch (error) {
      showAlert("Ошибка при удалении лидов: " + error.message, "error");
    }
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedLeads(newSelection);
  };



  const columns = [
    { field: "id", headerName: "Id", width: 50, sortable: false },
    {
      field: "full_name",
      headerName: "Имя",
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <a
          href={`/form/${params.row.id}`} // Ссылка на страницу
          style={{ 
            cursor: "pointer", 
            color: colors.greenAccent[300], 
            textDecoration: "none" 
          }}
          target="_self" // По умолчанию открывается в текущем окне
          onClick={(e) => {
            e.preventDefault(); // Блокируем переход по умолчанию
            navigate(`/form/${params.row.id}`); // Навигация через React Router
          }}
        >
          {params.value}
        </a>
      ),
    },
    { field: "leadStatusTitle", headerName: "Статус", width: 150, sortable: false },
    { field: "email", headerName: "Email", width: 150, sortable: false },
    { field: "phone", headerName: "Номер", width: 100, sortable: false },
    { field: "country_code", headerName: "Код Страны", width: 50, sortable: false },
    { field: "userFullName", headerName: "К кому прикреплен", width: 100, sortable: false },
    { field: "database_id", headerName: "База", width: 100, valueGetter: (params) => databases.find(database => database.id === params.row.database_id)?.title, sortable: false },
    { field: "lastNoteText", headerName: "Последний коментарий", width: 150, sortable: false },

    {
      field: "createdAt",
      headerName: "Дата создания",
      width: 150,
      sort: "desc",
      valueFormatter: (params) =>
        moment(params.value).format("HH:mm:ss DD.MM.YYYY"), // Форматирование даты
    },
    {
      field: "updatedAt",
      headerName: "Дата обновления",
      width: 150,
      sort: "desc",
      valueFormatter: (params) =>
        moment(params.value).format("HH:mm:ss DD.MM.YYYY"), // Форматирование даты
    },
  ];


  const showManagementButtons = me?.role === "admin" || me?.role === "teamlead";

  return (
    <Box m="20px">
      <Header title="Лиды" subtitle="Здесь отображается список ваших лидов" />
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Box>
          <Box display="flex" gap="16px" marginBottom="16px" flexDirection="row">
            <Box display="flex" gap="16px" marginBottom="16px" flexDirection="column" sx={{ marginBottom: "10px" }}>  
              <Box display="flex" gap="16px" marginBottom="6px" flexDirection="row" flexWrap={"wrap"} >
                <StatusesFilter
                  statuses={statuses}
                  selectedStatuses={selectedStatuses}
                  onChange={setSelectedStatuses}
                />
                <BasesFilter
                  bases={databases}
                  selectedBases={selectedDatabase}
                  onChange={setSelectedDatabase}
                />
                {(user.role === "admin" || user.role === "teamlead") && (
                  <UsersFilter
                    users={users}
                    selectedUsers={selectedUsers}
                    onChange={setSelectedUsers}
                  />
                )}
              </Box>
              <Box display="flex" gap="16px" marginBottom="6px" flexDirection="row" flexWrap={"wrap"}>
                <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
                  <DatePicker
                    label="Дата создания от"
                    format="DD.MM.YYYY"
                    value={dateCreatedStart !== null ? moment(dateCreatedStart) : null}
                    onChange={(newValue) => setDateCreatedStart(newValue)}
                    renderInput={(params) => <TextField {...params} sx={{ width: "fit-content", minWidth: "150px" }} />}
                  />
                  <DatePicker
                    label="Дата создания до"
                    format="DD.MM.YYYY"
                    value={dateCreatedEnd !== null ? moment(dateCreatedEnd) : null}
                    onChange={(newValue) => setDateCreatedEnd(newValue)}
                    renderInput={(params) => <TextField {...params} sx={{ width: "fit-content", minWidth: "150px" }} />}
                  />
                  <DatePicker
                    label="Дата обновления от"
                    format="DD.MM.YYYY"
                    value={dateUpdatedStart !== null ? moment(dateUpdatedStart) : null}
                    onChange={(newValue) => setDateUpdatedStart(newValue)}
                    renderInput={(params) => <TextField {...params} sx={{ width: "fit-content", minWidth: "150px" }} />}
                  />
                  <DatePicker
                    label="Дата обновления до"
                    format="DD.MM.YYYY"
                    value={dateUpdatedEnd !== null ? moment(dateUpdatedEnd) : null}
                    onChange={(newValue) => setDateUpdatedEnd(newValue)}
                    renderInput={(params) => <TextField {...params} sx={{ width: "fit-content", minWidth: "150px" }} />}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box display="flex" gap="16px" flexDirection="column" width="65px">
            <Button onClick={handleApplyFilters} variant="outlined" color="secondary" sx={{ height: "50%" }}>
                Применить фильтр
            </Button>
            <Button onClick={handleResetFilters} variant="outlined" color="secondary" sx={{ height: "50%" }}>
                Сбросить фильтры
            </Button>
            </Box>
          </Box>
          <DataGrid
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            checkboxSelection={!(me?.role === "ret" || me?.role === "manager")}    
            selectionModel={selectedLeads}
            rows={leads}
            columns={columns}
            disableRowSelectionOnClick={me?.role === "ret" || me?.role === "manager"}
            components={{
              Toolbar: () => (
                  <LeadsButtonToolbar
                    onDelete={handleDelete}
                    selectedCount={selectedLeads.length}
                    onPickChange={handlePickChange}
                    users={users}
                    statuses={statuses}
                    onStatusChange={handleChangeStatus}
                    onOpenModal={openModal}
                    onUpdate={updateLeads}
                    no_delete={me?.role !== "admin"}
                    no_button={!showManagementButtons}
                    onExport={handleExport}
                    exportDisabled={false}
                    
                  />
                  
              ),
            }}
            onSelectionModelChange={(newSelection) => {
              if (me?.role === "ret" || me?.role === "manager") {
                setSelectedLeads(newSelection.slice(-1));
              } else {
                setSelectedLeads(newSelection);
              }
            }}
            loading={loading}
            pagination
            paginationMode="server"
            rowCount={totalCount}
            page={page}
            pageSizeOptions={[25, 50, 100]}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            autoHeight

            filterMode="server"
            onFilterModelChange={onFilterChange}
          />
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} pb={3}>
            <TextField
              type="number"
              label="Перейти на страницу"
              value={pageJump}
              onChange={handlePageJumpChange}
              slotProps={{ input: { min: 1, max: Math.ceil(totalCount / pageSize) } }}
              error={pageJump === "0" || parseInt(pageJump) < 1}
              helperText={pageJump === "0" || parseInt(pageJump) < 1 ? "Неверный номер страницы" : ""}
            />
            <Button onClick={handlePageJump} variant="contained" color="primary" sx={{ ml: 2 }}>
              Перейти
            </Button>
          </Box>
        </Box>
      </Box>
      
      <UploadModal
        open={isModalOpen}
        onClose={closeModal}
        onSave={(message, type) => {
          // console.log(message, type);
          showAlert(message, type);
          closeModal();
          updateLeads();
        }}
        colors={colors}
      />
    </Box>
  );
};

export default Leads;
