import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";

const StatusModal = ({ isOpen, onClose, statusData, onSubmit, colors }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [titleError, setTitleError] = useState(""); // Ошибка для title
  const [descriptionError, setDescriptionError] = useState(""); // Ошибка для description

  useEffect(() => {
    if (statusData) {
      setTitle(statusData.title);
      setDescription(statusData.description || "");
      setIsChanged(false); // No changes initially
    } else {
      setTitle("");
      setDescription("");
      setIsChanged(true); // New entry by default allows changes
    }
  }, [statusData]);

  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length > 32) {
      setTitleError("Название не должно превышать 32 символа");
    } else {
      setTitleError("");
    }
    setTitle(value);
    if (statusData) {
      setIsChanged(value !== statusData.title);
    }
    setIsFormValid(!!title && (isChanged || !statusData) && !titleError);
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length > 255) {
      setDescriptionError("Описание не должно превышать 255 символов");
    } else {
      setDescriptionError("");
    }
    setDescription(value);
    if (statusData) {
      setIsChanged(value !== statusData.description);
    }
    setIsFormValid(!!title && (isChanged || !statusData) && !titleError && !descriptionError);
  };

  const handleSubmit = () => {
    if (!isFormValid) return;

    const newStatusData = { title, description };
    onSubmit(newStatusData);
    onClose();
  };

  useEffect(() => {
    setIsFormValid(!!title && (isChanged || !statusData) && !titleError && !descriptionError);
  }, [title, description, isChanged, statusData, titleError, descriptionError]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: colors.primary[400],
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: 400,
        }}
      >
        <h2>{statusData ? "Редактировать статус" : "Создать статус"}</h2>
        <TextField
          label="Название"
          variant="outlined"
          fullWidth
          required
          value={title}
          onChange={handleTitleChange}
          sx={{ mb: 2 }}
          error={!!titleError} // Показать ошибку для title
          helperText={titleError || (title.trim() === "" ? "Это поле обязательно" : "")} // Сообщение об ошибке
        />
        <TextField
          label="Описание"
          variant="outlined"
          fullWidth
          multiline
          value={description}
          onChange={handleDescriptionChange}
          sx={{ mb: 2 }}
          error={!!descriptionError} // Показать ошибку для description
          helperText={descriptionError} // Сообщение об ошибке
        />
        <Button variant="contained" onClick={handleSubmit} disabled={!isFormValid}>
          {statusData ? "Сохранить" : "Создать"}
        </Button>
      </Box>
    </Modal>
  );
};

export default StatusModal;
