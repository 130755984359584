import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme, TextField, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ruRU } from "@mui/x-data-grid";
import BaseModal from "../../modals/BaseModal";
import Header from "../../components/Header";
import GridButtonToolbar from "../../toolbars/GridButtonToolbar";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { getBases, createBase, deleteBase, editBase } from "../../services/baseService";
import UserContext from "../../contexts/userContext";
import { AuthContext } from "../../contexts/authContext";
import DeleteConfirmationModal from "../../modals/DeleteConfirmationModal"; // Добавляем модалку для удаления
import { titleConfig } from "../../data/config";

const Bases = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [bases, setBases] = useState([]);
  const [selectedBases, setSelectedBases] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingBase, setEditingBase] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const { showAlert } = useContext(UserContext);
  const [me, setMe] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [pageJump, setPageJump] = useState(page + 1);
  const [filterModel, setFilterModel] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // Состояние для модалки подтверждения удаления

  const updateBases = async () => {
    setLoading(true); // Устанавливаем флаг загрузки
    try {
      let search;
      if (filterModel?.quickFilterValues?.length > 0) {
        if (filterModel?.quickFilterValues?.length === 1) {
          search = filterModel?.quickFilterValues[0];
        } else {
          search = filterModel?.quickFilterValues.join(" ");
        }
      } else {
        search = "";
      }
      const data = await getBases(page, pageSize, search);
      setBases(data.leadsDatabases);
      setTotalCount(data.totalCount);
    } catch (error) {
      console.error("Failed to load bases", error);
    } finally {
      setLoading(false); // Устанавливаем флаг загрузки в false
    }
  };

  useEffect(() => {
    document.title = titleConfig.BASES;  // Устанавливаем заголовок страницы
  }, []);

  useEffect(() => {
    if (user) {
      setMe(user);
    }
  }, [user]);

  useEffect(() => {
    const loadBases = async () => {
      setLoading(true);
      try {
        let search;
        if (filterModel?.quickFilterValues?.length > 0) {
          if (filterModel?.quickFilterValues?.length === 1) {
            search = filterModel?.quickFilterValues[0];
          } else {
            search = filterModel?.quickFilterValues.join(" ");
          }
        } else {
          search = "";
        }
        const data = await getBases(page, pageSize, search);
        setBases(data.leadsDatabases);
        setTotalCount(data.totalCount);
      } catch (error) {
        console.error("Failed to load bases", error);
      } finally {
        setLoading(false);
      }
    };

    loadBases();
  }, [page, pageSize, filterModel, selectedBases]);

  const handleCopyToken = (token) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(token).then(
        () => showAlert("Token copied to clipboard", "success"),
        (error) => showAlert("Failed to copy token: " + error, "error")
      );
    } else {
      showAlert("Clipboard API not available", "error");
    }
  };

  const handleEdit = () => {
    if (selectedBases.length === 1) {
      const baseToEdit = bases.find((base) => base.id === selectedBases[0]);
      setEditingBase(baseToEdit);
      setOpenModal(true);
    }
  };

  const onFilterChange = (model) => {
    setFilterModel(model); // Update filter model state
  };

  const handlePageChange = (newPage) => setPage(newPage);
  const handlePageSizeChange = (newPageSize) => setPageSize(newPageSize);

  const handlePageJump = () => {
    if (pageJump > 0 && pageJump <= Math.ceil(totalCount / pageSize)) {
      setPage(pageJump - 1);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50, sortable: false },
    { field: "title", headerName: "Название базы", width: 200, sortable: false },
    {
      field: "isPurchased",
      headerName: "Покупная база",
      sortable: false,
      width: 150,
      renderCell: (params) =>
        params.value ? <MonetizationOnIcon color="success" /> : "", // Отображаем монетку для покупных баз
    },
    {
      field: "token",
      headerName: "Токен",
      width: 300,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            handleCopyToken(params.value);
          }}
          sx={{
            color: colors.greenAccent[200],
            textTransform: "lowercase",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          {params.value}
        </Button>
      ),
    },
  ];

  const handleCreate = () => {
    setEditingBase(null);
    setOpenModal(true);
  };

  const handleSave = async (baseData) => {
    try {
      if (editingBase) {
        await editBase(baseData.name, editingBase.id, baseData.isPurchased);
        showAlert("База успешно сохранена!", "success");
      } else {
        await createBase(baseData.name, baseData.isPurchased);
        showAlert("База успешно создана!", "success");
      }
      await updateBases();
      setSelectedBases(null);
      setOpenModal(false);
    } catch (error) {
      console.error("Failed to save base", error);
      showAlert("Ошибка при сохранении базы", "error");
    }
  };

  const handleDelete = async () => {
    try {
      await Promise.all(selectedBases.map(async (id) => await deleteBase(id)));
      await updateBases();
      setSelectedBases([]);
      setDeleteModalOpen(false); // Закрываем модалку после удаления
      showAlert("База успешно удалена!", "success");
    } catch (error) {
      console.error("Failed to delete base(s)", error);
      showAlert("Ошибка при удалении базы", "error");
    }
  };

  return (
    <Box m="20px">
      <Header title="Базы" subtitle="Управление базами" />
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          disableColumnSorting
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          rows={bases}
          columns={columns}
          loading={loading}
          checkboxSelection
          pagination
          paginationMode="server"
          rowCount={totalCount}
          page={page}
          pageSizeOptions={[25, 50, 100]}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          onSelectionModelChange={(newSelectionModel) => setSelectedBases(newSelectionModel)}
          components={{
            Toolbar: () => (
              <GridButtonToolbar
                onCreate={handleCreate}
                onEdit={handleEdit}
                onDelete={() => setDeleteModalOpen(true)} // Открываем модалку подтверждения удаления
                selectedCount={(selectedBases || []).length}
                no_delete={me?.role !== "admin"}
                no_edit={me?.role !== "admin"}
              />
            ),
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <TextField
          type="number"
          label="Перейти на страницу"
          value={pageJump}
          onChange={(e) => setPageJump(Number(e.target.value))}
          slotProps={{ input: { min: 1, max: Math.ceil(totalCount / pageSize) } }}
        />
        <Button onClick={handlePageJump} variant="contained" color="primary" sx={{ ml: 2 }}>
          Перейти
        </Button>
      </Box>
      
      <BaseModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCreate={handleSave}
        colors={colors}
        baseData={editingBase}
      />

      {/* Модалка для подтверждения удаления */}
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
        colors={colors}
        text="Вы уверены, что хотите удалить выбранные базы?"
      />
    </Box>
  );
};

export default Bases;
