import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";

const BaseModal = ({ open, onClose, onCreate, baseData, colors }) => {
  const [name, setName] = useState("");
  const [isPurchased, setIsPurchased] = useState(false); // Новый стейт для флага покупной базы
  const [error, setError] = useState(""); // Стейт для ошибки

  useEffect(() => {
    if (baseData) {
      setName(baseData.title);
      setIsPurchased(baseData.isPurchased || false); // Устанавливаем значение при редактировании базы
    } else {
      setName("");
      setIsPurchased(false); // Сбрасываем значение при создании новой базы
    }
  }, [baseData]);

  const handleSubmit = () => {
    onCreate({ name, isPurchased }); // Передаем оба значения
    onClose();
  };

  const handleNameChange = (e) => {
    const value = e.target.value;

    if (value.length > 18) {
      setError("Название не должно превышать 18 символов");
    } else {
      setError("");
    }

    setName(value);
  };

  const isSaveDisabled = !name.trim() || name.length > 18 || (baseData && baseData.title === name && baseData.isPurchased === isPurchased);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: colors.primary[400],
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: 400,
        }}
      >
        <TextField
          label="Название базы"
          value={name}
          onChange={handleNameChange}
          fullWidth
          error={!!error} // Ошибка при превышении длины
          helperText={error || (name.trim() === "" ? "Это поле обязательно" : "")} // Сообщение об ошибке
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isPurchased}
              onChange={(e) => setIsPurchased(e.target.checked)} // Управляем состоянием чекбокса
              color="primary"
            />
          }
          label="Покупная база"
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSaveDisabled} // Кнопка неактивна при ошибке или отсутствии текста
        >
          {baseData ? "Сохранить" : "Создать"}
        </Button>
      </Box>
    </Modal>
  );
};

export default BaseModal;
