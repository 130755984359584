import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Slider, IconButton, Card, CardContent } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import DownloadIcon from "@mui/icons-material/Download";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import {apiConfig} from "../data/config";

const AudioPlayer = ({ recordings }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState({}); 
  const [volume, setVolume] = useState(1);
  const audioRef = useRef(null);
  const [records, setRecords] = useState(recordings);

  const handlePlayPause = (recording) => {
    if (currentAudio && currentAudio.src === recording.recordingUrl) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(`${apiConfig.MUSIC_BASE_URL}${recording.recordingUrl}`);
      audioRef.current.volume = volume;
      audioRef.current.play();
      setCurrentAudio(audioRef.current);
      setIsPlaying(true);
      
      audioRef.current.ontimeupdate = () => {
        setProgress((prevProgress) => ({
          ...prevProgress,
          [recording.id]: (audioRef.current.currentTime / audioRef.current.duration) * 100,
        }));
      };

      audioRef.current.onended = () => {
        setIsPlaying(false);
        setProgress((prevProgress) => ({ ...prevProgress, [recording.id]: 0 }));
      };
    }
  };

  useEffect(() => {
    setRecords(recordings);
  }, [recordings]);

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    if (audioRef.current) {
      audioRef.current.volume = newValue;
    }
  };

  const handleProgressChange = (event, newValue, recordingId) => {
    if (audioRef.current) {
      audioRef.current.currentTime = (audioRef.current.duration / 100) * newValue;
      setProgress((prevProgress) => ({ ...prevProgress, [recordingId]: newValue }));
    }
  };

  const formatTime = (seconds) => {
    if (isNaN(seconds) || seconds < 0) {
      return '00:00'; // Default to 00:00 if seconds is NaN or negative
    }
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };



  return (
  <Box>
    {records && records.length > 0 ? (
      records.map((record) => (
        <Card
          key={record.id}
          sx={{
            marginBottom: 2,
            backgroundColor: theme.palette.mode === "dark" ? colors.primary[600] : colors.primary[900],
          }}
        >
          <CardContent>
            <Typography variant="h5" sx={{ color: colors.grey[100] }} gutterBottom>
              {record.title}
            </Typography>
            <Typography variant="body2" sx={{ color: colors.grey[300] }}>
              {record.description}
            </Typography>

            <Box display="flex" alignItems="center" marginTop={2}>
              <IconButton onClick={() => handlePlayPause(record)}>
                {currentAudio && currentAudio.src === record.recordingUrl && isPlaying ? (
                  <PauseIcon sx={{ color: colors.grey[100] }} />
                ) : (
                  <PlayArrowIcon sx={{ color: colors.grey[100] }} />
                )}
              </IconButton>

              <Slider
                value={progress[record.id] || 0}
                onChange={(event, newValue) => handleProgressChange(event, newValue, record.id)}
                sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, color: colors.blueAccent[400] }}
              />

              <VolumeUpIcon sx={{ color: colors.grey[100], marginRight: 1 }} />
              <Slider
                value={volume}
                onChange={handleVolumeChange}
                step={0.01}
                min={0}
                max={1}
                sx={{ width: "100px", marginRight: 2, color: colors.blueAccent[400] }}
              />

              <IconButton component="a" href={`${apiConfig.MUSIC_BASE_URL}${record.recordingUrl}`} download>
                <DownloadIcon sx={{ color: colors.grey[100] }} />
              </IconButton>
            </Box>

            {/* Time display */}
            {currentAudio && currentAudio.src === record.recordingUrl && (
              <Box display="flex" justifyContent="space-between" sx={{ marginTop: 1 }}>
                <Typography variant="body2" sx={{ color: colors.grey[300] }}>
                  {formatTime(audioRef.current.currentTime)} / {formatTime(audioRef.current.duration || 0)}
                </Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" sx={{ marginTop: 1 }}>
              <Typography variant="body2" sx={{ color: colors.grey[300] }}>
                {new Date(record.createdAt).toLocaleString()} 
                <span style={{ marginLeft: "40px" }}>{record.userFullName}</span>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))
    ) : (
      <Typography variant="h6" sx={{ color: colors.grey[300], textAlign: "center", marginTop: 2 }}>
        Нет доступных записей.
      </Typography>
    )}
  </Box>
);

};

export default AudioPlayer;
