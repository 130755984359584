import React, { useState, useEffect, useContext } from "react";
import { Modal, Box, Button, Typography, MenuItem, Select, TextField, IconButton, useTheme } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { uploadFile, confirmUpload, deleteLeadsFile } from "../services/leadsService";
import { getBases } from "../services/baseService";
import UserContext from "../contexts/userContext";

const UploadModal = ({ open, onClose, colors, onSave }) => {
  const [file, setFile] = useState(null);
  const [fileKey, setFileKey] = useState("");
  const [properties, setProperties] = useState([]);
  const [mappings, setMappings] = useState({});
  const [rows, setRows] = useState([]);
  const [bases, setBases] = useState([]);
  const [selectedBase, setSelectedBase] = useState("");
  const [itemsBackground, setItemsBackground] = useState({});
  const [errors, setErrors] = useState({});

  const theme = useTheme();
  const { showAlert } = useContext(UserContext);

  // Загрузка списка баз данных
  useEffect(() => {
    const fetchBases = async () => {
      try {
        const pageSize = 100;
        let currentPage = 1;
        let allBases = [];
        let totalBases = 0;

        const initialData = await getBases(currentPage, pageSize, "");
        totalBases = initialData.totalCount;
        allBases = initialData.leadsDatabases;

        while (allBases.length < totalBases) {
          currentPage += 1;
          const nextData = await getBases(currentPage, pageSize, "");
          allBases = [...allBases, ...nextData.leadsDatabases];
        }

        setBases(allBases);
      } catch (error) {
        console.error("Error fetching bases:", error);
      }
    };

    fetchBases();
  }, []);

  // Изменение фона в зависимости от темы
  useEffect(() => {
    switch (theme.palette.mode) {
      case "dark":
        setItemsBackground(colors.primary[300]);
        break;
      case "light":
        setItemsBackground(colors.grey[800]);
        break;
      default:
        setItemsBackground(colors.primary[300]);
        break;
    }
  }, [theme]);

  // Очистка данных при закрытии модалки
  useEffect(() => {
    if (!open) {
      // Удаление файла с сервера, если он был загружен
      if (fileKey) {
        deleteLeadsFile(fileKey).catch((error) => console.error("Error deleting file:", error));
      }

      // Очистка всех состояний, связанных с файлом
      setFile(null);
      setFileKey("");
      setProperties([]);
      setMappings({});
      setRows([]);
      setSelectedBase("");
      setErrors({});
    }
  }, [open, fileKey]);

  const handleFileInputClick = () => {
    document.getElementById('file-upload').click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel", // .xls
      "text/csv" // .csv
    ];
    const allowedExtensions = [".xlsx", ".xls", ".csv"];
    const fileExtension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();

    if (allowedTypes.includes(file.type) && allowedExtensions.includes(fileExtension)) {
      // Удаляем старый файл с сервера, если существует
      if (fileKey) {
        deleteLeadsFile(fileKey).catch((error) => console.error("Error deleting file:", error));
      }

      setFile(file);
      setFileKey(""); // При смене файла очищаем ключ
      setProperties([]); // Очищаем старые свойства
      setMappings({}); // Очищаем старые сопоставления
      setRows([]); // Очищаем старые строки
      setErrors((prev) => ({ ...prev, file: "" })); // Сбрасываем ошибку для файла
    } else {
      setErrors((prev) => ({ ...prev, file: "Пожалуйста, загрузите файл формата XLSX, XLS или CSV." }));
    }
  };

  const handleSendFile = async () => {
    if (!file) {
      setErrors((prev) => ({ ...prev, file: "Выберите файл для загрузки." }));
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData);
      setFileKey(response.fileKey);
      setProperties(response.headers);
      setRows(response.rows);
      setErrors((prev) => ({ ...prev, file: "" })); // Сбрасываем ошибку для файла
    } catch (error) {
      setErrors((prev) => ({ ...prev, file: "Ошибка при загрузке файла." }));
    }
  };

  const isSaveButtonEnabled = () => {
    return selectedBase && Object.keys(mappings).length === properties.length;
  };

  const handleSelectChange = (index, value) => {
    setMappings((prevMappings) => {
      const newMappings = { ...prevMappings };

      if (value === "") {
        delete newMappings[index];
      } else {
        for (const key in newMappings) {
          if (newMappings[key] === value) {
            delete newMappings[key];
          }
        }
        newMappings[index] = value;
      }

      return newMappings;
    });
  };

  const handleSaveMapping = async () => {
    if (!selectedBase) {
      setErrors((prev) => ({ ...prev, base: "Пожалуйста, выберите базу данных." }));
      return;
    }

    const transformedMappings = {};
    for (let key in mappings) {
      const headerName = mappings[key];
      const headerIndex = properties.findIndex(prop => prop.property_name === headerName);
      if (headerIndex !== -1) {
        transformedMappings[headerName] = String(headerIndex);
      }
    }

    const data = {
      fileKey,
      database_id: selectedBase,
      mappings: transformedMappings,
    };
    const { message, type } = await confirmUpload(data);
    onSave(message, type);
  };

  const selectedProperties = Object.values(mappings);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: colors.primary[400],
          border: `2px solid ${colors.greenAccent[500]}`,
          boxShadow: 24,
          p: 4,
          width: "fit-content",
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            label="Выбранный файл"
            value={file ? file.name : ""}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            sx={{
              backgroundColor: itemsBackground,
              color: colors.grey[100],
            }}
          />
          <IconButton
            onClick={handleFileInputClick}
            component="span"
            sx={{
              backgroundColor: itemsBackground,
              color: colors.grey[100],
            }}
          >
            <UploadFileIcon />
          </IconButton>
          <input
            type="file"
            id="file-upload"
            accept=".xlsx, .csv, .xls"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </Box>
        {errors.file && (
          <Typography sx={{ color: colors.red[500], mt: 1 }}>
            {errors.file}
          </Typography>
        )}
        <Button variant="contained" sx={{ mt: 2, bgcolor: colors.blueAccent[500] }} onClick={handleSendFile}>
          Загрузить файл
        </Button>

        {properties.length > 0 && (
          <>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ color: colors.greenAccent[300] }}>
                Выберите базу данных:
              </Typography>
              <Select
                value={selectedBase}
                onChange={(e) => setSelectedBase(e.target.value)}
                displayEmpty
                sx={{ width: "100%", mb: 2, backgroundColor: itemsBackground }}
              >
                <MenuItem value="">
                  <em>Не выбрано</em>
                </MenuItem>
                {bases && bases.length > 0 ? (
                  bases.map((base) => (
                    <MenuItem key={base.id} value={base.id}>
                      {base.title}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>Нет доступных баз данных</MenuItem>
                )}
              </Select>
              {errors.base && (
                <Typography sx={{ color: colors.red[500], mt: 1 }}>
                  {errors.base}
                </Typography>
              )}

              <Typography variant="h6" sx={{ color: colors.greenAccent[300] }}>
                Таблица данных:
              </Typography>
              <table border="1" cellPadding="5" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    {Array.from({ length: rows[0].length }).map((_, index) => (
                      <th key={index} style={{ width: "100px", textAlign: "center" }}>
                        <Select
                          value={mappings[index] || ""}
                          onChange={(e) => handleSelectChange(index, e.target.value)}
                          displayEmpty
                          sx={{
                            width: "100%",
                            backgroundColor: mappings[index] ? colors.greenAccent[400] : "transparent",
                          }}
                        >
                          <MenuItem value="">
                            <em>Не выбрано</em>
                          </MenuItem>
                          {properties.map((prop) => (
                            <MenuItem
                              key={prop.property_name}
                              value={prop.property_name}
                              disabled={selectedProperties.includes(prop.property_name)}
                            >
                              {prop.verboseName}
                            </MenuItem>
                          ))}
                        </Select>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell.column}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                bgcolor: colors.greenAccent[500],
                '&:disabled': { bgcolor: colors.grey[500] },
              }}
              onClick={handleSaveMapping}
              disabled={!isSaveButtonEnabled()}
            >
              Сохранить сопоставление
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UploadModal;
