import axios from 'axios';
import { apiConfig } from '../data/config';

const authAxios = axios.create({
  baseURL: `${apiConfig.API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

let navigateToLogin;
let updateAuthStatus;
let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = () => {
  refreshSubscribers.forEach(callback => callback());
  refreshSubscribers = [];
};

const setAuthFunctions = (navigateFunc, updateStatusFunc) => {
  navigateToLogin = navigateFunc;
  updateAuthStatus = updateStatusFunc;
};

authAxios.interceptors.response.use(
  response => response,
  async error => {
    const { config, response } = error;
    const originalRequest = config;

    if (response && response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Отправляем запрос на обновление сессии
          await axios.get(`${apiConfig.API_BASE_URL}/api/auth/refresh`, {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          });

          isRefreshing = false;
          onRefreshed(); 
          return authAxios(originalRequest); // Повторяем запрос
        } catch (refreshError) {
          isRefreshing = false;
          updateAuthStatus(false);
          navigateToLogin();
          return Promise.reject(refreshError);
        }
      }

      // Ожидаем завершения текущего запроса на обновление сессии
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh(() => {
          // Повторяем оригинальный запрос после завершения обновления
          resolve(authAxios(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export { setAuthFunctions };
export default authAxios;
