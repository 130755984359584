import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button, Box, IconButton } from '@mui/material';
import AudioPlayer from '../../../components/AudioPlayer';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { createRecording } from '../../../services/recordingService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Recordings = ({ records, colors, theme, lead_id, setRecords, showAlert }) => {
    const [newRecordingTitle, setNewRecordingTitle] = useState('');
    const [newRecordingDescription, setNewRecordingDescription] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [recordings, setRecordings] = useState(records);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ["audio/mpeg", "audio/wav"];
        const allowedExtensions = [".mp3", ".wav"];
        const fileExtension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
    
        if (allowedTypes.includes(file.type) && allowedExtensions.includes(fileExtension)) {
            setSelectedFile(file);
        } else {
            alert("Пожалуйста, загрузите аудиофайл формата MP3 или WAV.");
        }
    };

    useEffect(() => {
        setRecordings(records); 
    }, [records]);

    const handleFileInputClick = () => {
        document.getElementById('file-upload').click();
    };


    const handleUploadFile = async () => {
        if (!newRecordingTitle.trim()) {
            alert("Введите название записи.");
            return;
        }
    
        if (!selectedFile) {
            alert("Выберите файл.");
            return;
        }
    
        try {
            const newRecording = await createRecording(
                selectedFile,
                newRecordingTitle,
                newRecordingDescription || "", 
                lead_id
            );
            setRecords((prevRecords) => [...prevRecords, newRecording]);
            setSelectedFile(null);
            setNewRecordingTitle("");
            setNewRecordingDescription("");
            document.getElementById('file-upload').value = ""; 
            showAlert("Запись успешно загружена!", "success");
        } catch (error) {
            console.error("Ошибка при загрузке записи:", error);
        }
    };

  return (
    <Accordion style={{ backgroundColor: theme.palette.mode === 'dark' ? colors.primary[500] : colors.primary[400] }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: colors.grey[100] }}/>}>
                    <Typography variant="h4">Записи разговора</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AudioPlayer recordings={recordings} />
                    <TextField 
                        fullWidth
                        label="Название записи"
                        value={newRecordingTitle}
                        onChange={(e) => setNewRecordingTitle(e.target.value)}
                        sx={{ mb: 2, mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        multiline
                        label="Описание записи"
                        value={newRecordingDescription}
                        onChange={(e) => setNewRecordingDescription(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Box display="flex" alignItems="center" gap={2}>
                        <TextField
                            label="Выбранный файл"
                            value={selectedFile ? selectedFile.name : ""}
                            variant="outlined"
                            aria-readonly
                            sx={{
                                backgroundColor: colors.blueAccent[500],
                                color: colors.grey[100],
                            }}
                        />
                        <IconButton
                            onClick={handleFileInputClick}
                            component="span"
                            sx={{
                                backgroundColor: colors.blueAccent[500],
                                color: colors.grey[100],
                            }}
                        >
                            <UploadFileIcon />
                        </IconButton>
                        <input
                            type="file"
                            id="file-upload"
                            accept=".mp3, .wav"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    <Button
                        onClick={handleUploadFile}
                        variant="contained"
                        disabled={!newRecordingTitle.trim() || !selectedFile}
                        sx={{
                            mt: 2,
                            backgroundColor: colors.blueAccent[500],
                            color: colors.grey[100],
                        }}
                    >
                        Загрузить запись
                    </Button>
                </AccordionDetails>
                
            </Accordion>
  );
};

export default Recordings;