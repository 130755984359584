import axios from 'axios';
import authAxios from './authAxios'
import {apiConfig} from '../data/config';

export const axiosInstance = axios.create({
  baseURL: `${apiConfig.API_BASE_URL}/api`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Логин запрос
export const loginRequest = async (username, password) => {
  const response = await axiosInstance.post('/auth/login', {
    username,
    password,
  });
  
  return response.data; // Сервер должен отправить куки и данные
};

// Логаут запрос
export const logoutRequest = async () => {
  const response = await authAxios.get('/auth/logout');
  return response.status;
};
