import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Button, Box, IconButton } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createNote, pinNote } from '../../../services/commentService';

const Comments = ({ comments, setComments, me, lead_id, showAlert, blockBackground, colors, theme }) => {
  const [newComment, setNewComment] = useState('');
  const [hoveredCommentId, setHoveredCommentId] = useState(null);

  const handlePinComment = async (commentId) => {
    try {
      await pinNote(commentId, true);
      const updatedComments = comments.map((comment) =>
        comment.id === commentId ? { ...comment, pinned: true } : comment
      );
      setComments(updatedComments);
    } catch (error) {
      console.error("Ошибка при закреплении комментария:", error);
    }
  };

  const handleUnpinComment = async (commentId) => {
    try {
      await pinNote(commentId, false);
      const updatedComments = comments.map((comment) =>
        comment.id === commentId ? { ...comment, pinned: false } : comment
      );
      setComments(updatedComments);
    } catch (error) {
      console.error("Ошибка при откреплении комментария:", error);
    }
  };

  const handleAddComment = async () => {
    if (newComment.trim()) {
      try {
        const noteData = {
          text: newComment,
          lead_id: Number(lead_id),
        };

        const createdNote = await createNote(noteData);

        setComments((prevComments) => [
          ...prevComments,
          {
            id: createdNote.id,
            text: createdNote.text,
            createdAt: createdNote.createdAt,
            userFullName: me?.full_name,
          },
        ]);

        setNewComment('');
        showAlert('Комментарий успешно добавлен!', 'success');
      } catch (error) {
        console.error('Ошибка при добавлении комментария:', error);
      }
    }
  };

  const sortedComments = [
    ...comments.filter((comment) => comment.pinned).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    ...comments.filter((comment) => !comment.pinned).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  ];

  return (
    <Accordion
      defaultExpanded
      style={{
        backgroundColor: theme.palette.mode === 'dark' ? colors.primary[500] : colors.primary[400],
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: colors.grey[100] }} />}>
        <Typography variant="h4">Комментарии</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          fullWidth
          multiline
          variant="filled"
          label="Добавить комментарий"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <Button onClick={handleAddComment} variant="contained" sx={{ mt: 2, mb: 2 }}>
          Добавить комментарий
        </Button>
        {sortedComments.map((comment) => (
          <Box
            key={comment.id}
            sx={{
              mb: 3,
              backgroundColor: blockBackground,
              borderRadius: '8px',
              p: 1,
              position: 'relative',
            }}
            onMouseEnter={() => setHoveredCommentId(comment.id)}
            onMouseLeave={() => setHoveredCommentId(null)}
          >
            <Typography variant="body1">{comment.text}</Typography>
            <Box display="flex" justifyContent="space-between" sx={{ marginTop: 1 }}>
              <Typography variant="body2" sx={{ color: colors.grey[300] }}>
                {new Date(comment.createdAt).toLocaleString()}
                <span style={{ marginLeft: '40px' }}>{comment.userFullName}</span>
              </Typography>
              <IconButton
                onClick={() =>
                  comment.pinned ? handleUnpinComment(comment.id) : handlePinComment(comment.id)
                }
                sx={{
                  position: 'absolute', // Устанавливаем позиционирование
                  top: 8, // Отступ сверху
                  right: 8, // Отступ справа
                  visibility: hoveredCommentId === comment.id || comment.pinned ? 'visible' : 'hidden',
                  color: comment.pinned ? colors.blueAccent[500] : colors.grey[500],
                  ':hover': {
                    color: comment.pinned ? colors.blueAccent[300] : colors.grey[300],
                  },
                }}
              >
                <PushPinIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default Comments;
