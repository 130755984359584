import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ruRU } from "@mui/x-data-grid";
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import Header from "../../components/Header";
import GridButtonToolbar from "../../toolbars/GridButtonToolbar";
import UserModal from "../../modals/UserModal";
import UserContex from "../../contexts/userContext";
import { AuthContext } from "../../contexts/authContext";
import { getUsers, createUser, editUser, deleteUser } from "../../services/adminService";
import { titleConfig } from "../../data/config";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingUser, setEditingUser] = useState();
  const [users, setUsers] = useState([]);
  const [me, setMe] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const { showAlert } = useContext(UserContex);

  const updateUsers = async () => {
    setLoading(true);
    try {
      const users = await getUsers();
      setUsers(users);
      setLoading(false);
    } catch (error) {
      console.error("Ошибка при получении пользователей:", error);
    }
  };

  useEffect(() => {
    document.title = titleConfig.USERS;  // Устанавливаем заголовок страницы
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const users = await getUsers();
        setUsers(users);
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при получении пользователей:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    if (user) {
      setMe(user);
    }
  }, [user]);

  const handleCreate = () => {
    setEditingUser(null);
    setOpenModal(true);
  };

  const handleEdit = () => {
    if (selectedUsers.length === 1) {
      const userToEdit = users.find((user) => user.id === selectedUsers[0]);
      setEditingUser(userToEdit);
      setOpenModal(true);
    } else {
      showAlert("Выберите одного пользователя для редактирования.", "warning");
    }
  };

  const handleDelete = async () => {
    try {
      for (const userId of selectedUsers) {
        await deleteUser(userId);
      }
      showAlert(
        selectedUsers.length > 1
          ? "Пользователи успешно удалены"
          : "Пользователь успешно удален",
        "success"
      );
      await updateUsers();
      setSelectedUsers([]);
    } catch (error) {
      showAlert("Ошибка при удалении пользователя: " + error.message, "error");
    }
  };


  const handleSave = async (userData) => {
    try {
      if (editingUser) {
        const updatedFields = {};
  
        if (userData.full_name && userData.full_name !== editingUser.full_name) {
          updatedFields.full_name = userData.full_name;
        }
        if (userData.username && userData.username !== editingUser.username) {
          updatedFields.username = userData.username;
        }
        if (userData.role && userData.role !== editingUser.role) {
          updatedFields.role = userData.role;
        }
        if (userData.password) {
          updatedFields.password = userData.password;
        }
  
        if (Object.keys(updatedFields).length > 0) {
          await editUser(updatedFields, editingUser.id);
          await updateUsers();
          
          showAlert("Пользователь успешно обновлен!", "success");
        }
      } else {
        await createUser(
          userData.full_name,
          userData.username,
          userData.password,
          userData.role
        );
        await updateUsers();
        showAlert("Пользователь успешно создан!", "success");
      }
      setEditingUser(null);
      setOpenModal(false);
      setSelectedUsers([]);
    } catch (error) {
      showAlert("Ошибка при сохранении пользователя: " + error.message + "!", "error");
    }
  };
  

  const columns = [
    { field: "id", headerName: "Id", width: 70, sortable: false },
    {
      field: "full_name",
      headerName: "Имя",
      width: 200,
      cellClassName: "name-column--cell",
      sortable: false
    },
    {
      field: "username",
      headerName: "Имя пользователя",
      width: 200,
      sortable: false
    },
    {
      field: "role",
      headerName: "Роль",
      sortable: false,
      width: 140,
      renderCell: ({ row: { role } }) => (
        <Box
          width="100%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
          backgroundColor={
            role === "admin" || role === "teamlead"
              ? colors.greenAccent[600]
              : colors.greenAccent[800]
          }
          borderRadius="4px"
        >
          {(role === "admin" || role === "teamlead") && <AdminPanelSettingsOutlinedIcon />}
          {role === "aff" && <TransferWithinAStationIcon />}
          {role === "manager" && <WifiCalling3Icon />}
          {role === "ret" && <PhonelinkRingIcon />}
          <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
            {role === "admin" && "Админ"}
            {role === "aff" && "Афилейт"}
            {role === "teamlead" && "ТимЛид"}
            {role === "manager" && "Менеджер"}
            {role === "ret" && "Ретен"}
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Пользователи" subtitle="Список пользователей" />
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          disableColumnSorting
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          selectionModel={selectedUsers}
          checkboxSelection
          rows={users}
          columns={columns}
          
          components={{
            Toolbar: () => (
              <GridButtonToolbar
                onCreate={handleCreate}
                onEdit={handleEdit}
                onDelete={handleDelete}
                no_delete={me?.role !== "admin"}
                no_edit={me?.role !== "admin"}
                selectedCount={selectedUsers.length}
              />
            ),
          }}
          loading={loading}
          onSelectionModelChange={(newSelection) => {
            setSelectedUsers(newSelection);
          }}
        />
      </Box>

      <UserModal

        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={handleSave}
        user={editingUser}
        colors={colors}
      />
    </Box>
  );
};

export default Team;
