import React from "react";
import { Modal, Box, Button, Typography } from "@mui/material";

const DeleteConfirmationModal = ({ open, onClose, onConfirm, colors, text }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: colors.primary[400],
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: 400,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" component="h2">
          {text}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            Отмена
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm}>
            Удалить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
