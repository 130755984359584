import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchEvents } from "../services/calendarService";
import { AuthContext } from "./authContext";
import { useTheme } from "@mui/material/styles";
import { io } from "socket.io-client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiConfig } from "../data/config";

const Context = createContext();

const UserProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(localStorage.getItem("filter") || "all");
  const [notifications, setNotifications] = useState(JSON.parse(localStorage.getItem("notifications")) || []);
  const { user, setUser, isAuthenticated } = useContext(AuthContext);
  const theme = useTheme();
  // Инициализация сокета
  const [socket, setSocket] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  let blinkInterval;

  useEffect(() => {
    if (isAuthenticated && !socket) {
      const newSocket = io(apiConfig.API_BASE_URL,
      {
        withCredentials: true,
      }
      ); 
      setSocket(newSocket);

      // Обработка входящих уведомлений
      newSocket.on("notification", (notification) => {
        addNotification(notification);
      });

      // Закрываем соединение при выходе пользователя
      return () => newSocket.close();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const currentTitle = document.title; // Запоминаем текущий заголовок
  
    if (notifications.length > unreadCount) {
      setUnreadCount(notifications.length);
  
      blinkInterval = setInterval(() => {
        if (!window.location.pathname.includes("form")) { 
          document.title = document.title === "Новое уведомление" 
            ? currentTitle 
            : "Новое уведомление";
        }
      }, 1000);
    } else {
      clearInterval(blinkInterval);
      if (!window.location.pathname.includes("form")) {
        document.title = currentTitle;
      }
    }
  
    return () => clearInterval(blinkInterval);
  }, [notifications]); 
  

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const eventsData = await fetchEvents();
        setEvents(eventsData);
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    if (isAuthenticated) loadEvents();
  }, [isAuthenticated]);

  useEffect(() => {
    localStorage.setItem("filter", currentFilter);
  }, [currentFilter]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem("events", JSON.stringify(events));
  }, [events]);


  const removeStorage = () => {
    setUser(null);
    setEvents([]);
    localStorage.removeItem("user");
    localStorage.removeItem("events");
    localStorage.removeItem("notifications");
  };

  const addNotification = (message) => {
    setNotifications((prev) => {
      const updatedNotifications = [...prev, message];
      localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
      return updatedNotifications;
    });
  };

  const removeNotification = (notificationId) => {
    setNotifications((prev) => {
      const updatedNotifications = prev.filter((notif) => notif.messageId !== notificationId);
      localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
      return updatedNotifications;
    });
  };

  const showAlert = (message, type) => {
    switch (type) {
      case "error":
        toast.error(message);
        break;
      case "success":
        toast.success(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      case "info":
        toast.info(message);
        break;
      default:
        toast(message);
    }
  };
  

  const addNewEvent = (newEvent) => {
    setEvents((prevEvents) => {
      const updatedEvents = [...prevEvents, newEvent];
      localStorage.setItem("events", JSON.stringify(updatedEvents));
      return updatedEvents;
    });
  };

  const removeEvent = (eventId) => {
    setEvents((prevEvents) => {
      const updatedEvents = prevEvents.filter(event => String(event.id) !== String(eventId));
      localStorage.setItem("events", JSON.stringify(updatedEvents));
      return updatedEvents;
    });
  };

  return (
    <Context.Provider value={{
      removeStorage,
      events,
      addNewEvent,
      removeEvent,
      notifications,
      removeNotification,
      setCurrentFilter,
      currentFilter,
      showAlert
    }}>
      {children}
      <ToastContainer position="bottom-right" theme={theme.palette.mode}/>
    </Context.Provider>
  );
};

export default Context;
export { UserProvider };
