import authAxios from "./authAxios";



export const getNotes = async (page, pageSize, search) => {
    try {
        const response = await authAxios.get("/notes", {
            params: {
                page,
                pageSize,
                search
            }
        });
        
        if (response.status === 200) {
          return {
            notes: response.data.notes,
            totalCount: response.data.totalCount
          }
        } else {
            console.error("Error getting notes")
          throw new Error("Error getting notes");
        }
    } catch (error) {
        console.error(`Error getting notes:, ${error}`);
        throw error;
    }
}



export const createNote = async (note) => {
    try {
        const response = await authAxios.post(
            "/notes",
            note
        )
        if (response.status === 201) {
            return response.data
        } else if (response.status === 400) {
            throw new Error("Error while creating note. Invalid request")
        } else {
            throw new Error("Error while creating note")
        }
    } catch (error) {
        console.error("Error while creating note: ", error)
        throw error
    }
}



export const editNote = async (newNoteData, noteId) => {
    try {
        const response = await authAxios.put(
            `/notes/${noteId}`,
            newNoteData
        )
        if (response.status === 200) {
            return response.data
        } else if (response.status === 404) {
            throw new Error("Error while editing note. Note is not found")
        } else {
            throw new Error("Error while editing note")
        }
    } catch (error) {
        console.error("Error while editing note: ", error)
        throw error
    }
}

export const pinNote = async (noteId, pinned) => {
    try {
        const response = await authAxios.put(
            `/notes/${noteId}`,
            { pinned }
        )
        if (response.status === 200) {
            return response.data
        } else if (response.status === 404) {
            throw new Error("Error while pinning note. Note is not found")
        } else {
            throw new Error("Error while pinning note")
        }
    } catch (error) {
        console.error("Error while pinning note: ", error)
        throw error
    }
}



export const deleteNote = async (noteId) => {
    try {
        const response = await authAxios.delete(`/notes/${noteId}`)
        if (response.status === 200) {
            return true
        } else if (response.status === 404) {
            throw new Error("Error while deleting note. Note is not found")
        } else {
            throw new Error("Error while deleting notes")
        }
    } catch (error) {
        console.error("Error while deleting notes: ", error)
        throw error
    }
}