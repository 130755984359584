import authAxios from "./authAxios";

export const getStats = async () => {
    try {
        const response = await authAxios.get("/dashboard/get-stats");
        if (response.status === 200) {
            return {
                stats: response.data.stats,
                deposits: response.data.last10Deposits
            }
        } else {
            console.error("Error while getting dashboard data");
            throw new Error("Error while getting dashboard data");
        }
    } catch (error) {
        console.error("Error while getting dashboard data: ", error);
        throw error;
    }
}




export const getCustomStats = async (start_date, end_date) => {
    try {
        const response = await authAxios.get("/dashboard/custom-period-stats", {
            params: {
                start_date,
                end_date
            }
        });
        if (response.status === 200) {
            return {
                stats: response.data.stats,
                deposits: response.data.last10Deposits
            }
        } else {
            console.error("Error while getting dashboard data");
            throw new Error("Error while getting dashboard data");
        }
    } catch (error) {
        console.error("Error while getting dashboard data: ", error);
        throw error;
    }
}

