import authAxios from "./authAxios";

export const getBases = async (page, pageSize, search) => {
    try {
        const response = await authAxios.get("/leads_databases", {
            params: {
                page,
                pageSize,
                search
            }
        });
        
        if (response.status === 200) {
          return {
            leadsDatabases: response.data.leadsDatabases,
            totalCount: response.data.totalCount
          }
        } else {
            console.error("Error getting bases")
          throw new Error("Error getting bases");
        }
    } catch (error) {
        console.error(`Error getting bases:, ${error}`);
        throw error;
    }
}





export const createBase = async (baseName, isPurchased) => {
    try {
        const response = await authAxios.post(
            "/leads_databases",
            {
                "title": baseName,
                isPurchased
            }
        );
    
        if (response.status === 201) {
            return response.data
        } else {
            throw new Error(`Error creating base`)
        }
    } catch (error) {
        console.error(`Error creating base: ${error}`)
        throw error
    }
    
}



export const deleteBase = async (baseId) => {
    try {
        const response = await authAxios.delete(`/leads_databases/${baseId}`)
        if (response.status === 200) {
            return true
        } else {
            throw new Error(`Error deleting base`)
        }
    } catch (error) {
        console.error(`Error deleting base: ${error}`)
        throw error
    }
    
}


export const editBase = async (newBaseName, baseId, isPurchased) => {
    try {
        const response = await authAxios.put(
            `/leads_databases/${baseId}`,
            {
                "title": newBaseName,
                "is_purchased": isPurchased
            }
        );
    
        if (response.status === 200) {
            return response.data
        } else {
            throw new Error(`Error creating base`)
        }
    } catch (error) {
        console.error(`Error creating base: ${error}`)
        throw error
    }
}
