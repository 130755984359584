import authAxios from "./authAxios";

export const getStatuses = async () => {
    try {
        const response = await authAxios.get("/leads_statuses")
        if (response.status === 200) {
            return response.data
        } else {
            console.error("Error while getting statuses")
            throw new Error("Error while getting statuses")
        }
    } catch (error) {
        console.error("Error while getting statuses: ", error)
        throw error
    }
}


export const createStatus = async (statusData) => {
    try {
        const response = await authAxios.post(
            "/leads_statuses",
            statusData
        )
        if (response.status === 201) {
            return response.data
        } else {
            console.error("Error while creating status")
            throw new Error("Error while creating status")
        }
    } catch (error) {
        console.error("Error while creating status: ", error)
        throw error
    }
}


export const editStatus = async (newStatusData, status_id) => {
    try {
        const response = await authAxios.put(
            `/leads_statuses/${status_id}`, 
            newStatusData
        )
        if (response.status === 200) {
            return response.data
        } else {
            console.error("Error while editing status")
        }
    } catch (error) {
        console.error("Error while editing status: ")
        throw error 
    }
}

export const deleteStatus = async (status_id) => {
    try {
        const response = await authAxios.delete(`/leads_statuses/${status_id}`)
        if (response.status === 200) {
            return response.status
        } else if (response.status === 404) {
            console.error("Error while deleting status. Status is not found")
            throw new Error("Error while deleting status. Status is not found")
        } else {
            console.error("Error while editing status")
            throw new Error("Error while deleting status")
            
        }
    } catch (error) {
        console.error("Error while editing status: ", error)
        throw error
    }
}