import authAxios from "./authAxios";

export const getDeposits = async (page, pageSize, search) => {
    try {
        const response = await authAxios.get("/deposits", {
            params: {
                page,
                pageSize,
                search
            }
        });
        
        if (response.status === 200) {
          return {
            deposits: response.data.deposits,
            totalCount: response.data.totalCount
          }
        } else {
            console.error("Error getting deposits")
          throw new Error("Error getting deposits");
        }
    } catch (error) {
        console.error(`Error getting deposits:, ${error}`);
        throw error;
    }
}



export const createDeposit = async (depositData) => {
    try {
        const response = await authAxios.post(
            "/deposits",
            depositData
        )
        if (response.status === 201) {
            return response.data
        } else if (response.status === 400) {
            throw new Error("Error while creating deposit. Invalid request")
        }
    } catch (error) {
        console.error("Error while creating deposit: ", error)
        throw error
    }
}



export const deleteDeposit = async (depositId) => {
    try {
        const response = await authAxios.delete(`/deposits/${depositId}`)
        if (response.status === 200) {
            return true
        } else if (response.status === 404) {
            throw new Error("Error while deleting deposit. Deposit is not found")
        } else {
            throw new Error("Error while getting deposits")
        }
    } catch (error) {
        console.error("Error while getting deposits: ", error)
        throw error
    }
}

export const editDeposit = async (newAmount, depositId) => {
    try {
        const response = await authAxios.put(
            `/deposits/${depositId}`,
            newAmount
        )
        if (response.status === 200) {
            return response.data
        } else if (response.status === 404) {
            throw new Error("Error while editing deposit. Deposit is not found")
        } else {
            throw new Error("Error while editing deposits")
        }
    } catch (error) {
        console.error("Error while editing deposit: ", error)
        throw error
    }
}
