import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";

const GridButtonToolbar = ({ onCreate = () => {}, onDelete, selectedCount, no_edit = false, onEdit = () => {}, no_create = false, no_delete = false }) => {
    return (
      <GridToolbarContainer>
        <Box display="flex" width="100%" justifyContent="space-between" flexWrap={"nowrap"} marginBottom={"8px"}>
            <Box display="flex" width="100%">
                <GridToolbarQuickFilter />
            </Box>
            <Box display="flex" gap="8px">
                {!no_create && <Button
                    variant="contained"
                    color="primary"
                    onClick={onCreate}
                    disabled={selectedCount > 0}
                >
                    Создать
                </Button>}
                {!no_edit && <Button
                    variant="contained"
                    color="secondary"
                    onClick={onEdit}
                    disabled={selectedCount !== 1}
                >
                    Изменить
                </Button>}
                {!no_delete && <Button
                    variant="contained"
                    color="error"
                    onClick={onDelete}
                    disabled={selectedCount === 0}
                >
                    Удалить
                </Button>}

            </Box>
            
        </Box>
      </GridToolbarContainer>
    );
};

export default GridButtonToolbar;
