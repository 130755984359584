import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { getStatuses, createStatus, editStatus, deleteStatus } from "../../services/statusService";
import Header from "../../components/Header";
import GridButtonToolbar from "../../toolbars/GridButtonToolbar";
import StatusModal from "../../modals/StatusModal";
import { AuthContext } from "../../contexts/authContext";
import UserContext from "../../contexts/userContext";
import { titleConfig } from "../../data/config";

const Statuses = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [me, setMe] = useState(null);
  const { user } = useContext(AuthContext);
  const { showAlert } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      setMe(user);
    }
  }, [user]);

  useEffect(() => {
    document.title = titleConfig.STATUSES;  // Устанавливаем заголовок страницы
  }, []);


  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const fetchedStatuses = await getStatuses();
        setStatuses(fetchedStatuses);
      } catch (error) {
        showAlert("Ошибка при загрузке статусов: " + error.message, "error");
      }
    };
    fetchStatuses();
  }, []);

  const handleCreate = () => {
    setCurrentStatus(null);
    setOpenModal(true);
  };

  const handleEdit = () => {
    if (selectedStatuses.length === 1) {
      const statusToEdit = statuses.find(status => status.id === selectedStatuses[0]);
      setCurrentStatus(statusToEdit);
      setOpenModal(true);
    }
  };

  const handleDelete = async () => {
    if (selectedStatuses.length > 0) {
      try {
        await Promise.all(selectedStatuses.map(async (id) => await deleteStatus(id)));
        setStatuses(prev => prev.filter(status => !selectedStatuses.includes(status.id)));
        showAlert("Статусы успешно удалены!", "success");
        setSelectedStatuses([]);
      } catch (error) {
        showAlert("Ошибка при удалении статусов: " + error.message, "error");
      }
    }
  };

  const handleModalSubmit = async (statusData) => {
    try {
      if (currentStatus) {
        // Edit status
        const updatedStatus = await editStatus(statusData, currentStatus.id);
        setStatuses(prev => prev.map(status => (status.id === currentStatus.id ? updatedStatus : status)));
        
        showAlert("Статус успешно обновлен!", "success");
      } else {
        // Create new status
        const newStatus = await createStatus(statusData);
        setStatuses(prev => [...prev, newStatus]);
        showAlert("Статус успешно создан!", "success");
      }
      setSelectedStatuses([]);
      setOpenModal(false);
    } catch (error) {
      showAlert("Ошибка при сохранении статуса: " + error.message, "error");
    }
  };

  const columns = [
    { field: "id", headerName: "Id", width: 50, sortable: false },
    {
      field: "title",
      headerName: "Название",
      width: 200,
      sortable: false
    },
    { field: "description", headerName: "Описание", width: 300, sortable: false },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Статусы" subtitle="Заполните информацию о статусах" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          disableColumnSorting
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          checkboxSelection
          selectionModel={selectedStatuses}
          rows={statuses}
          columns={columns}
          components={{
            Toolbar: () => (
              <GridButtonToolbar
                onCreate={handleCreate}
                onDelete={handleDelete}
                onEdit={handleEdit}
                no_delete={me?.role !== "admin"}
                no_edit={me?.role !== "admin"}
                selectedCount={selectedStatuses.length}
              />
            ),
          }}
          onSelectionModelChange={(newSelection) => {
            setSelectedStatuses(newSelection);
          }}
        />
      </Box>
      <StatusModal 
        isOpen={openModal} 
        onClose={() => setOpenModal(false)} 
        statusData={currentStatus} 
        onSubmit={handleModalSubmit} 
        colors={colors}
      />
    </Box>
  );
};

export default Statuses;
