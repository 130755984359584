import {
  Box,
  Typography,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import Grid from "@mui/material/Grid2";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import ProgressCircle from "../../components/ProgressCircle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState, useContext } from "react";
import { getStats, getCustomStats } from "../../services/dashboardService";
import UserContext from "../../contexts/userContext";
import StatBox from "../../components/StatBox";
import { titleConfig } from "../../data/config";
import DateRangePicker from "../../components/DateRangePicker";



const Dashboard = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const [period, setPeriod] = useState("monthlyStats");
  const [stats, setStats] = useState({});
  const [deposits, setDeposits] = useState([]);
  const [customStart, setCustomStart] = useState(moment().subtract(1, "month"));
  const [customEnd, setCustomEnd] = useState(moment());
  const [blockBackground, setBackgroundColor] = useState(colors.primary[500]);
  const { showAlert } = useContext(UserContext);


  useEffect(() => {
    document.title = titleConfig.DASHBOARD;  // Устанавливаем заголовок страницы
  }, []);


  const defaultStats = {
    conversionRate: 0,
    totalLeads: 0,
    totalLeadCost: 0,
    averageLeadCost: 0,
    validLeads: 0,
    invalidLeads: 0,
    closedLeads: 0,
    leadsUnderQuestion: 0,
  };

  useEffect(() => {
    const loadStats = async () => {
      try {
        let fetchedStats = {};
        let fetchedDeposits = [];

        if (period === "custom") {
          const response = await getCustomStats(
            customStart.format("YYYY-MM-DD"),
            customEnd.format("YYYY-MM-DD")
          );
          fetchedStats = response?.stats || defaultStats;
          fetchedDeposits = response?.deposits || [];
        } else {
          const response = await getStats();
          fetchedStats = response?.stats?.[period] || defaultStats;
          fetchedDeposits = response?.deposits || [];
        }

        setStats(fetchedStats);
        setDeposits(fetchedDeposits);
      } catch (error) {
        showAlert("Не удалось загрузить данные: " + error.message, "error");
        setStats(defaultStats);
        setDeposits([]);
      }
    };

    loadStats();
  }, [period, customStart, customEnd]);

  useEffect(() => {
    setBackgroundColor(
      theme.palette.mode === "dark" ? colors.primary[500] : colors.grey[900]
    );
  }, [theme.palette.mode]);

  const handlePeriodChange = (event, value) => {
    setPeriod(value);
  };

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header
          title="DASHBOARD"
          subtitle={
            period === "dailyStats"
              ? "Дневная статистика"
              : period === "weeklyStats"
              ? "Недельная статистика"
              : period === "monthlyStats"
              ? "Месячная статистика"
              : "Кастомная статистика"
          }
        />
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={2}>
        {/* Left Column */}
        <Box flex={1} display="flex" flexDirection="column" gap={2}>
          <Box backgroundColor={colors.primary[400]} p="10px" gap={2}>
            <Tabs value={period} onChange={handlePeriodChange}>
              <Tab label="День" value="dailyStats" />
              <Tab label="Неделя" value="weeklyStats" />
              <Tab label="Месяц" value="monthlyStats" />
              <Tab label="Кастом" value="custom" />
            </Tabs>
            {period === "custom" && (
              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Начало периода"
                    value={customStart}
                    onChange={(date) => setCustomStart(date)}
                  />
                  <DatePicker
                    label="Конец периода"
                    value={customEnd}
                    onChange={(date) => setCustomEnd(date)}
                  />
                  {/* <DateRangePicker
                    startValue={customStart}
                    endValue={customEnd}
                    onChange={({ start, end }) => {
                      setCustomStart(start);
                      setCustomEnd(end);
                    }}
                  /> */}
                </LocalizationProvider>
              </Box>
            )}

            <Grid mt={2} container spacing={2} justifyContent="center">
              {/* StatBoxes */}
              {[
                { title: "Количество FTD", value: stats?.totalFtdDepositCount },
                { title: "Конверсия", value: stats?.conversionRate, isProgress: true },
                { title: "Количество новых лидов", value: stats?.totalLeads },
                { title: "Сумма затрат", value: `$${stats?.totalLeadCost || 0}` },
                { title: "Средняя цена лида", value: `$${stats?.averageLeadCost || 0}` },
                { title: "Сумма депозитов ретена", value: `$${stats?.totalRetentionDepositsAmount || 0}` },
                { title: "Цена закрытого лида", value: `$${stats?.averageClosedLeadAmount || 0}` },
                { title: "Валидных лидов", value: stats?.validLeads || 0, isFull: true, totalCount: stats?.totalLeads || 0 },
                { title: "Невалидных лидов", value: stats?.invalidLeads || 0, isFull: true, totalCount: stats?.totalLeads || 0 },
                { title: "Закрытых лидов", value: stats?.closedLeads  || 0, isFull: true, totalCount: stats?.totalLeads || 0 },
                { title: "Непотенциальных лидов", value: stats?.nonPotentialLeads || 0, isFull: true, totalCount: stats?.totalLeads || 0 },
                { title: "Расходы на WhatsApp", value: `$${stats?.totalExpensesSumByCategory?.WhatsApp || 0}`, isFull: true, totalCount: stats?.totalLeadCost || 0 },
                { title: "Расходы на телефонию", value: `$${stats?.totalExpensesSumByCategory?.Voip || 0}`, isFull: true, totalCount: stats?.totalLeadCost || 0 },
                { title: "Расходы на базу", value: `$${stats?.totalExpensesSumByCategory?.Databases || 0}`, isFull: true, totalCount: stats?.totalLeadCost || 0 },
                { title: "Расходы на расходники", value: `$${stats?.totalExpensesSumByCategory?.Consumables || 0}`, isFull: true, totalCount: stats?.totalLeadCost || 0 }
              ].map(({ title, value, isProgress = false, isFull = false, totalCount }, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor={blockBackground}
                    p="20px"
                    borderRadius="8px"
                  >
                    {!isFull && <Typography
                      variant="h5"
                      color={colors.greenAccent[500]}
                      sx={{ fontWeight: "bold" }}
                    >
                      {title}
                    </Typography>}
                    {isProgress ? (
                      <ProgressCircle size="50" progress={value || 0} />
                    ) : isFull ? (
                      <StatBox 
                        title={title} 
                        subtitle={value} 
                        progress={
                          totalCount !== 0 && parseInt(
                            String(value).includes("$") 
                              ? String(value).replace("$", "") 
                              : value
                          ) !== 0 
                            ? parseInt(
                                String(value).includes("$") 
                                  ? String(value).replace("$", "") 
                                  : value
                              ) / totalCount 
                            : "0.00"
                        }
                      />
                    ) : (
                      <Typography
                        variant="h4"
                        color={colors.greenAccent[600]}
                        sx={{ fontWeight: "bold", mt: "10px" }}
                      >
                        {value}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        {/* Right Column */}
        <Box
          flex={1}
          backgroundColor={colors.primary[400]}
          maxHeight="100vh"
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
          >
            <Typography variant="h5" fontWeight="600">
              Последние депозиты
            </Typography>
          </Box>
          {deposits?.length > 0 ? (
            deposits.map((deposit) => (
              <Box
                key={deposit.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.primary[500]}`}
                p="15px"
              >
                <Box>
                  <Typography variant="h5" fontWeight="600">
                    {deposit.leadFullName || "Без имени"}
                  </Typography>
                  <Typography>
                    {moment(deposit.createdAt).format("DD.MM.YYYY HH:mm")}
                  </Typography>
                </Box>
                <Typography>{deposit.amount || 0}$</Typography>
                <Typography>{deposit.userFullName || "Неизвестный"}</Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body1" p="15px">
              Депозитов пока нет
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
