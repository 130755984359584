import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { AuthContext } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { titleConfig } from '../../data/config';

const LoginPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { login, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();


  useEffect(() => {
    document.title = titleConfig.LOGIN;  // Устанавливаем заголовок страницы
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error before trying login
    try {
      const responseMessage = await login(username, password);
      if (responseMessage) {
        setErrorMessage(responseMessage); // Show server error message
      }
    } catch (error) {
      setErrorMessage("Ошибка при попытке входа. Попробуйте позже.");
      console.error("Ошибка логина:", error);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[800] : colors.primary[400],
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: colors.primary[400], // Matching modal style
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          borderRadius: "12px",
          width: 400,
        }}
      >
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Sign In
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="username"
            sx={{ mb: 2 }}
            error={Boolean(errorMessage)}
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            sx={{ mb: 2 }}
            error={Boolean(errorMessage)}
          />
          {errorMessage && (
            <Typography variant="body2" color="error" sx={{ textAlign: "center", mb: 2 }}>
              {errorMessage}
            </Typography>
          )}
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              bgcolor: colors.blueAccent[500],
              color: colors.grey[100],
              "&:hover": {
                bgcolor: colors.blueAccent[600],
              },
            }}
          >
            Login
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default LoginPage;
