import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, MenuItem } from "@mui/material";

const roles = ["admin", "teamlead", "manager", "ret"];

const UserModal = ({ open, onClose, onSave, user, colors }) => {
  const [formData, setFormData] = useState({
    full_name: "",
    username: "",
    password: "",
    role: roles[0],
  });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (user) {
      setFormData({
        full_name: user.full_name || "",
        username: user.username || "",
        password: "", // Пароль сбрасываем при редактировании
        role: user.role || roles[0],
      });
      setIsChanged(false); // Изменений изначально нет
    } else {
      setFormData({
        full_name: "",
        username: "",
        password: "",
        role: roles[0],
      });
    }
  }, [user]);

  const validate = (field, value) => {
    const newErrors = { ...errors };

    // Валидация логина (разрешены только латинские буквы, цифры, тире и подчеркивания)
    if (field === "username" || !field) {
      // Обновляем регулярное выражение для разрешения точки и собачки
      if (!/^[A-Za-z\d-_@.]+$/.test(formData.username)) {
        newErrors.username = "Логин должен содержать только английские буквы, цифры, тире, подчеркивания, точки и собачки.";
      } else {
        delete newErrors.username;
      }
    }

    // Валидация пароля только если это создание нового пользователя или если пароль изменен
    if (field === "password" || !field) {
      if (!user && !formData.password) {
        newErrors.password = "Пароль обязателен при создании пользователя.";
      } else if (formData.password && !/^[A-Za-z\d!@#$%^&*()_+={}:;'"\\|,.<>/?`~-]{8,}$/.test(formData.password)) {
        newErrors.password = "Пароль не должен содержать кириллицу и должен быть минимум 8 символов.";
      } else {
        delete newErrors.password;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (user) {
      // Проверка на изменения только при редактировании
      setIsChanged(
        (name === "full_name" && value !== user.full_name) ||
        (name === "username" && value !== user.username) ||
        (name === "password" && value) ||  // Проверка на изменения пароля
        (name === "role" && value !== user.role)
      );
    } else {
      // Если создание нового пользователя, то проверка по заполнению полей
      setIsChanged(true);
    }

    validate(name, value);
  };

  const handleSubmit = () => {
    if (validate()) {
      onSave(formData);
      setFormData({
        full_name: "",
        username: "",
        password: "",
        role: roles[0],
      });
      onClose();
    }
  };

  useEffect(() => {
    const checkFormValidity = () => {
      const isFilled = formData.full_name && formData.username && formData.role;
      setIsFormValid(isFilled && validate() && isChanged);
    };
    checkFormValidity();
  }, [formData, isChanged]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: colors.primary[400],
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: 400,
        }}
      >
        <TextField
          label="Полное имя"
          name="full_name"
          value={formData.full_name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Логин"
          name="username"
          value={formData.username}
          onChange={handleChange}
          fullWidth
          error={Boolean(errors.username)}
          helperText={errors.username}
        />
        <TextField
          label="Пароль"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          fullWidth
          error={Boolean(errors.password)}
          helperText={errors.password}
        />
        <TextField
          select
          label="Роль"
          name="role"
          value={formData.role}
          onChange={handleChange}
          fullWidth
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" onClick={handleSubmit} disabled={!isFormValid}>
          Сохранить
        </Button>
      </Box>
    </Modal>
  );
};

export default UserModal;
