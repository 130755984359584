import React, { useState, useEffect } from "react";
import { Box, useTheme, Modal, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ruRU } from "@mui/x-data-grid";
import { getDeposits, deleteDeposit } from "../../services/depositService";
import { formatDate } from "../../modules/formater";
import Header from "../../components/Header";
import GridButtonToolbar from "../../toolbars/GridButtonToolbar";
import UserContext from "../../contexts/userContext";
import { titleConfig } from "../../data/config";

const Deposits = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { showAlert } = React.useContext(UserContext);
  const [deposits, setDeposits] = useState([]);
  const [selectedDeposits, setSelectedDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [filterModel, setFilterModel] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Состояние для открытия модалки удаления

  
  useEffect(() => {
    document.title = titleConfig.DEPOSITS;  // Устанавливаем заголовок страницы
  }, []);


  // Fetch deposits from server with pagination and filtering
  useEffect(() => {
    const fetchDeposits = async () => {
      setLoading(true);
      try {
        let search;
        if (filterModel?.quickFilterValues?.length > 0) {
          search = filterModel?.quickFilterValues.join(" ");
        } else {
          search = "";
        }
        const data = await getDeposits(page, pageSize, search);
        setDeposits(data?.deposits || []);
        setTotalCount(data?.totalCount || 0);
      } catch (error) {
        console.error("Ошибка при получении депозитов:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDeposits();
  }, [page, pageSize, filterModel]);

  const handleDelete = async () => {
    setOpenDeleteModal(true); // Открываем модалку подтверждения удаления
  };

  const handleConfirmDelete = async () => {
    try {
      for (const id of selectedDeposits) {
        await deleteDeposit(id);
      }
      await updateDeposits(); // Обновить список депозитов после удаления
      showAlert("Депозиты успешно удалены", "success"); // Уведомление об успешном удалении
    } catch (error) {
      showAlert("Ошибка при удалении депозитов: " + error.message, "error"); // Уведомление об ошибке
    } finally {
      setOpenDeleteModal(false); // Закрываем модалку
      setSelectedDeposits([]); // Очищаем выбранные элементы
    }
  };

  const updateDeposits = async () => {
    try {
      let search;
      if (filterModel?.quickFilterValues?.length > 0) {
        search = filterModel?.quickFilterValues.join(" ");
      } else {
        search = "";
      }
      const data = await getDeposits(page, pageSize, search);
      setDeposits(data?.deposits || []); // Гарантируем, что это массив
      setTotalCount(data?.totalCount || 0);
    } catch (error) {
      console.error("Ошибка при обновлении депозитов:", error);
    }
  };

  const handlePageChange = (newPage) => setPage(newPage);
  const handlePageSizeChange = (newPageSize) => setPageSize(newPageSize);

  const columns = [
    { field: "id", headerName: "Id", width: 50, sortable: false },
    { field: "amount", headerName: "Сумма", width: 150, sortable: false },
    {
      field: "createdAt",
      headerName: "Дата создания",
      sortable: false,
      width: 150,
      renderCell: (params) => params.value ? formatDate(params.value) : "-",
    },
    { field: "userFullName", headerName: "Пользователь", width: 150, sorting: false },
    { field: "leadFullName", headerName: "Лид совершивший депозит", width: 150, sorting: false },
    { field: "depositStatusTitle", headerName: "Статус депозита ", width: 200, sorting: false },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Депозиты" subtitle="Список депозитов" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
          "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[100]} !important` },
        }}
      >
        <DataGrid
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          checkboxSelection
          rows={deposits}
          columns={columns}
          components={{
            Toolbar: () => (
              <GridButtonToolbar
                onDelete={handleDelete}
                selectedCount={selectedDeposits.length}
                no_edit={true}
                no_create={true}
              />
            ),
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedDeposits(newSelectionModel);
          }}
          loading={loading}
          pagination
          paginationMode="server"
          rowCount={totalCount}
          page={page}
          pageSizeOptions={[25, 50, 100]}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          filterMode="server"
          onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        />
      </Box>

      {/* Модалка для подтверждения удаления */}
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: colors.primary[400],
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: 400,
          }}
        >
          <Typography variant="h6" color="white">
            Вы уверены, что хотите удалить выбранные депозиты?
          </Typography>
          <Box display="flex" justifyContent="space-between" gap="10px">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenDeleteModal(false)}
              sx={{ width: "45%" }}
            >
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmDelete}
              sx={{ width: "45%" }}
            >
              Подтвердить
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Deposits;
