import React, { useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { useLocation, Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import CommentIcon from '@mui/icons-material/Comment';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";

// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import AddCardIcon from '@mui/icons-material/AddCard';
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/authContext"; // Импортируйте AuthContext
import SidebarClock from "../../../components/SidebarClock";

const Item = ({ title, to, icon, currentPath }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isCurrent = (currentPath === to)

  return (
    <MenuItem
      active={isCurrent}
      style={{
        color: colors.grey[100],
        display: "flex",
        alignItems: "center", // Горизонтальное выравнивание
      }}
      icon={icon}
    >
      <Link
        to={to}
        style={{
          textDecoration: "none",
          color: colors.grey[100],
          display: "flex",
          alignItems: "center",
          width: "100%", // Обеспечивает охват всего блока
        }}
        target="_self"
      >
        <Typography sx={{ color: isCurrent ? colors.greenAccent[500] : colors.grey[100], paddingLeft: "10px" }}>{title}</Typography>
      </Link>
    </MenuItem>
  );
};


const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const [isAdminOrTeamLead, setIsAdminOrTeamLead] = useState(false);
  

  // Используем контекст для получения данных пользователя
  const { user } = useContext(AuthContext); // Извлекаем данные пользователя из AuthContext

  useEffect(() => {
    // Проверяем, является ли пользователь администратором или менеджером
    setIsAdminOrTeamLead(user && (user.role === "admin" || user.role === "teamlead"));
  }, [user]);

  return (
    <>
      {isLoginPage ? (
        <></>
      ) : (
        <Box
          sx={{
            position: "sticky",
            display: "flex",
            height: "100vh",
            top: 0,
            bottom: 0,
            zIndex: 10000,
            "& .sidebar": {
              border: "none",
            },
            "& .menu-icon": {
              backgroundColor: "transparent !important",
            },
            "& .menu-item": {
              backgroundColor: "transparent !important",
            },
            "& .menu-anchor": {
              color: "inherit !important",
              backgroundColor: "transparent !important",
            },
            "& .menu-item:hover": {
              color: `${colors.blueAccent[500]} !important`,
              backgroundColor: "transparent !important",
            },
            "& .menu-item.active": {
              color: `${colors.greenAccent[500]} !important`,
              backgroundColor: "transparent !important",
            },
          }}
        >
          <Sidebar
            breakPoint="md"
            rtl={sidebarRTL}
            backgroundColor={colors.primary[400]}
            image={sidebarImage}
          >
            <Menu iconshape="square">
              <MenuItem
                icon={
                  collapsed ? (
                    <MenuOutlinedIcon onClick={() => collapseSidebar()} />
                  ) : sidebarRTL ? (
                    <SwitchLeftOutlinedIcon
                      onClick={() => setSidebarRTL(!sidebarRTL)}
                    />
                  ) : (
                    <SwitchRightOutlinedIcon
                      onClick={() => setSidebarRTL(!sidebarRTL)}
                    />
                  )
                }
                style={{
                  margin: "10px 0 20px 0",
                  color: colors.grey[100],
                }}
              >
                {!collapsed && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml="15px"
                  >
                    <Typography variant="h3" color={colors.grey[100]}>
                      HKEX-CRM
                    </Typography>
                    <IconButton
                      onClick={broken ? () => toggleSidebar() : () => collapseSidebar()}
                    >
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Box>
                )}
              </MenuItem>

              {!collapsed && (
                <Box mb="25px">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      "& .avater-image": {
                        backgroundColor: colors.primary[500],
                      },
                    }}
                  >
                    <SupportAgentIcon height={"50px"} />
                  </Box>
                  <Box textAlign="center">
                    <Typography
                      variant="h3"
                      color={colors.grey[100]}
                      fontWeight="bold"
                      sx={{ m: "10px 0 0 0" }}
                    >
                      {user?.full_name || "User"} {/* Отображаем имя пользователя */}
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box paddingLeft={collapsed ? undefined : "10%"}>
                

                <Item
                  title="Лиды"
                  to="/leads"
                  icon={<ContactsOutlinedIcon />}
                  currentPath={location.pathname}
                />
                <Item
                  title="Календарь"
                  to="/calendar"
                  icon={<CalendarTodayOutlinedIcon />}
                  currentPath={location.pathname}
                />

                {/* Проверяем роль пользователя, чтобы отобразить блок только для админа */}
                {isAdminOrTeamLead && (
                  <>
                    <Typography
                      variant="h6"
                      color={colors.grey[300]}
                      sx={{ m: "15px 20px 5px 20px" }}
                    >
                      Admin
                    </Typography>
                    <Item
                      title="Статистика"
                      to="/dashboard"
                      icon={<AnalyticsIcon />}
                      currentPath={location.pathname}
                    />
                    <Item
                      title="Пользователи"
                      to="/users"
                      icon={<AccountTreeIcon />}
                      currentPath={location.pathname}
                    />
                    <Item
                      title="Базы"
                      to="/bases"
                      icon={<ApartmentIcon />}
                      currentPath={location.pathname}
                    />
                    <Item
                      title="Статусы"
                      to="/statuses"
                      icon={<BookmarksIcon />}
                      currentPath={location.pathname}
                    />
                    {user?.role === "admin" && 
                    (
                    <>
                    <Item
                      title="Депозиты"
                      to="/deposits"
                      icon={<AddCardIcon />}
                      currentPath={location.pathname}
                    />
                    <Item
                      title="Комментарии"
                      to="/comments"
                      icon={<CommentIcon />}
                      currentPath={location.pathname}
                    />
                    <Item
                      title="Записи разговоров"
                      to="/recordings"
                      icon={<InterpreterModeIcon />}
                      currentPath={location.pathname}
                    />
                    </>
                  )}
                    <Item
                      title="Расходы"
                      to="/expenses"
                      icon={<RequestQuoteIcon />}
                      currentPath={location.pathname}
                    />
                    {/* <Item
                      title="FAQ Page"
                      to="/faq"
                      icon={<HelpOutlineOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    /> */}
                  </>
                )}
              </Box>
              <Box sx={{ marginTop: "20px", padding: "10px", display: "flex", justifyContent: sidebarRTL ? "flex-start" : "flex-end"}}>
                <SidebarClock colors={colors} theme={theme} />
              </Box>
            </Menu>
          </Sidebar>
        </Box>
      )}
    </>
  );
};

export default MyProSidebar;
