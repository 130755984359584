import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme, TextField, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { ruRU } from "@mui/x-data-grid";
import { getNotes, deleteNote, editNote } from "../../services/commentService";
import CommentModal from "../../modals/CommentModal";
import DeleteConfirmationModal from "../../modals/DeleteConfirmationModal"; 
import { formatDate } from "../../modules/formater";
import Header from "../../components/Header";
import GridButtonToolbar from "../../toolbars/GridButtonToolbar";
import UserContext from "../../contexts/userContext";
import { titleConfig } from "../../data/config";

const Comments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [comments, setComments] = useState([]);
  const [selectedComments, setSelectedComments] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [pageJump, setPageJump] = useState(page + 1);
  const [filterModel, setFilterModel] = useState();

  const { showAlert } = useContext(UserContext);

  useEffect(() => {
    document.title = titleConfig.COMMENTS;  // Устанавливаем заголовок страницы
  }, []);


  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);
      try {
        let search;
        if (filterModel?.quickFilterValues?.length > 0) {
          if (filterModel?.quickFilterValues?.length === 1) {
            search = filterModel?.quickFilterValues[0]; 
          } else {
            search = filterModel?.quickFilterValues.join(" ");
          }
        } else {
          search = "";
        }
        const data = await getNotes(page, pageSize, search);
        setComments(data.notes);
        setTotalCount(data.totalCount);
      } catch (error) {
        console.error("Failed to load notes", error);
      } finally {
        setLoading(false);
      }
    };
    fetchComments();
  }, [page, pageSize, filterModel]);

  const handleEdit = () => {
    if (selectedComments.length === 1) {
      const comment = comments.find(c => c.id === selectedComments[0]);
      setSelectedComment(comment);
      setModalOpen(true);
    }
  };

  const handleDelete = () => {
    if (selectedComments.length > 0) {
      setDeleteModalOpen(true); // Открыть модалку подтверждения удаления
    }
  };

  const confirmDelete = async () => {
    try {
        // Удаляем комментарии поштучно
        await Promise.all(selectedComments.map((commentId) => deleteNote(commentId)));
        
        // Показываем сообщение об успешном удалении
        showAlert("Комментарии успешно удалены", "success");
        setDeleteModalOpen(false);

        // Обновляем список комментариев
        let search;
        if (filterModel?.quickFilterValues?.length > 0) {
            if (filterModel?.quickFilterValues?.length === 1) {
                search = filterModel?.quickFilterValues[0];
            } else {
                search = filterModel?.quickFilterValues.join(" ");
            }
        } else {
            search = "";
        }

        const data = await getNotes(page, pageSize, search);
        setComments(data.notes);
        setTotalCount(data.totalCount);
        setSelectedComments([]);
    } catch (error) {
        console.error("Failed to delete comments", error);
        showAlert("Ошибка при удалении комментариев", "error");
    }
};


  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedComment(null);
  };

  const onFilterChange = (model) => {
    setFilterModel(model);
  };

  const handlePageChange = (newPage) => setPage(newPage);
  const handlePageSizeChange = (newPageSize) => setPageSize(newPageSize);

  const handlePageJump = () => {
    if (pageJump > 0 && pageJump <= Math.ceil(totalCount / pageSize)) {
      setPage(pageJump - 1);
    }
  };

  const handleSave = async (updatedComment) => {
    try {
      const editedComment = await editNote(
        { text: updatedComment.text },
        updatedComment.id
      );
      const updatedComments = comments.map((comment) =>
        comment.id === editedComment.id ? editedComment : comment
      );
      setComments(updatedComments);
      showAlert("Комментарий успешно обновлен", "success");
      setSelectedComments([]);
    } catch (error) {
      console.error("Ошибка при обновлении комментария:", error);
      showAlert("Ошибка при обновлении комментария", "error");
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50, sortable: false },
    { field: "text", headerName: "Текст", flex: 1, sortable: false },
    { field: "leadFullName", headerName: "К кому прикреплен", width: 100, sortable: false },
    { field: "userFullName",headerName: "Создатель", width: 100, sortable: false },
    { field: "createdAt", headerName: "Дата создания", width: 150, renderCell: (params) => formatDate(params.value), sortable: false },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Комментарии" subtitle="Список комментариев" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          checkboxSelection
          selectionModel={selectedComments}
          rows={comments}
          columns={columns}
          loading={loading}
          pagination
          paginationMode="server"
          rowCount={totalCount}
          page={page}
          pageSizeOptions={[25, 50, 100]}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          components={{
            Toolbar: () => (
              <GridButtonToolbar
                onDelete={handleDelete}
                onEdit={handleEdit}
                selectedCount={selectedComments.length}
                no_create={true}
              />
            ),
          }}
          onSelectionModelChange={(newSelection) => {
            setSelectedComments(newSelection);
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <TextField
          type="number"
          label="Перейти на страницу"
          value={pageJump}
          onChange={(e) => setPageJump(Number(e.target.value))}
          slotProps={{ input: { min: 1, max: Math.ceil(totalCount / pageSize) } }}
        />
        <Button onClick={handlePageJump} variant="contained" color="primary" sx={{ ml: 2 }}>
          Перейти
        </Button>
      </Box>
      <CommentModal
        open={modalOpen}
        onClose={handleModalClose}
        onSave={handleSave}
        commentData={selectedComment}
        colors={colors}
      />
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        colors={colors}
        text="Вы уверены, что хотите удалить выбранные комментарии?"
      />
    </Box>
  );
};

export default Comments;
