import React, { useContext, useState } from "react";
import { ColorModeContext } from "../../theme";
import { useTheme, Box, IconButton, Menu, MenuItem, Typography, Divider, Button, Badge } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useProSidebar } from "react-pro-sidebar";
import { AuthContext } from "../../contexts/authContext";
import UserContext from "../../contexts/userContext";

const NotificationsMenu = ({ notifications, onRemoveNotification, onClearNotifications }) => {
  const theme = useTheme();

  // Функция для форматирования даты
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, "0"); // Часы в 24-часовом формате
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Минуты
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяц (нумерация с 0)
    const day = String(date.getDate()).padStart(2, "0"); // День месяца

    return `${hours}:${minutes} | ${year}-${month}-${day}`;
  };

  return (
    <Box>
      {notifications.length === 0 ? (
        <MenuItem disabled sx={{ color: theme.palette.text.secondary }}>Нет новых уведомлений</MenuItem>
      ) : (
        notifications.map((notification) => (
          <MenuItem
            key={notification.messageId}
            onClick={() => onRemoveNotification(notification.messageId)}
            sx={{
              bgcolor: theme.palette.mode === "dark" ? theme.palette.primary[800] : theme.palette.background.paper,
              color: theme.palette.text.primary,
              "&:hover": { bgcolor: theme.palette.action.hover },
            }}
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle2" fontWeight="bold">
                  {notification.action}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ fontSize: "0.8rem" }}>
                  {formatDate(notification.date)} {/* Форматируем дату */}
                </Typography>
              </Box>
              <Typography variant="body2">{notification.message}</Typography>
            </Box>
          </MenuItem>
        ))
      )}
      {notifications.length > 0 && (
        <>
          <Divider />
          <Box textAlign="center" padding={1}>
            <Button color="secondary" onClick={onClearNotifications}>Удалить все</Button>
          </Box>
        </>
      )}
    </Box>
  );
};

const Topbar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const { logout } = useContext(AuthContext);
  const { notifications, removeNotification, removeStorage } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const logoutFunc = () => {
    removeStorage();
    logout();
  };

  return (
    <Box display="flex" justifyContent="space-between" paddingLeft="10px" paddingRight="10px">
      {/* Левая сторона */}
      <Box display="flex">
        {broken && !rtl && (
          <IconButton sx={{ margin: "0 6px 0 2px" }} onClick={toggleSidebar}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
      
      {/* Правая сторона */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
        </IconButton>
        
        {/* Добавляем Badge для отображения числа уведомлений */}
        <IconButton onClick={handleOpenMenu}>
          <Badge 
            badgeContent={notifications.length} // Число уведомлений
            color="error" // Красный цвет кружочка
            invisible={notifications.length === 0} // Скрываем кружок, если нет уведомлений
            sx={{
              '& .MuiBadge-badge': {
                right: -3,
                top: 13,
                border: `2px solid ${theme.palette.background.paper}`,
                padding: '0 4px',
              },
            }}
            anchorOrigin={{
              vertical: "top", // Позиционируем кружочек сверху
              horizontal: "right", // Справа от иконки
            }}
          >
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
        
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{
            "& .MuiPaper-root": {
              bgcolor: theme.palette.background.default,
              color: theme.palette.text.primary,
              boxShadow: theme.shadows[5],
            },
          }}
        >
          <NotificationsMenu
            notifications={notifications}
            onRemoveNotification={(id) => {
              removeNotification(id);
            }}
            onClearNotifications={() => {
              notifications.forEach((notif) => removeNotification(notif.messageId));
              handleCloseMenu();
            }}
          />
        </Menu>

        <IconButton onClick={logoutFunc}>
          <LogoutOutlinedIcon />
        </IconButton>
        
        {broken && rtl && (
          <IconButton sx={{ margin: "0 6px 0 2px" }} onClick={toggleSidebar}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
