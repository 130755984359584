import React, { useState } from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";

const BasesFilter = ({ bases, selectedBases, onChange }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue); // Обновляем значение поля ввода
  };

  const handleSelectionChange = (_, newValue) => {
    onChange(newValue.map((base) => ({id : base.id, title : base.title})));
  };

  // console.log("bases:", bases);
  // console.log("selectedBases:", selectedBases);

  return (
    <Autocomplete
      multiple
      options={bases.filter((base) =>
        base.title.toLowerCase().startsWith(inputValue.toLowerCase()) // Фильтрация по названию базы
      )}
      getOptionLabel={(option) => option.title} // Отображение названия базы
      value={selectedBases} // Отображение выбранных баз
      onChange={handleSelectionChange}
      onInputChange={handleInputChange}
      // sx={{ width: "300px" }} // Задаем ширину фильтра
      sx={{ width: "fit-content", minWidth: "150px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Фильтр по базам"
          placeholder="Введите базу"
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.title} // Отображение названия базы
            sx={{
              whiteSpace: "nowrap", // Текст в одну строку
              overflow: "hidden",   // Обрезка текста
              textOverflow: "ellipsis", // Многоточие, если текст не влезает
              margin: "2px", // Отступ между чипами
            }}
          />
        ))
      }
    />
  );
};

export default BasesFilter;
