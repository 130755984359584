import authAxios from "./authAxios"




export const getMe = async () => {
    try {
        const response = await authAxios.get('/users/me')
        return response.data
    } catch (err) {
        console.error("failed get user information:", err)
    }
}
