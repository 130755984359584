import React, { useState } from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";

const StatusesFilter = ({ statuses, selectedStatuses, onChange }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue); // Обновляем значение поля ввода
  };

  const handleSelectionChange = (_, newValue) => {
    onChange(newValue.map((status) => ({id : status.id, title : status.title}))); // Передаем список выбранных `id`
  };


  // console.log("statuses:", statuses);
  // console.log("selectedStatuses:", selectedStatuses);
  
  return (
    <Autocomplete
      multiple
      options={statuses.filter((status) =>
        status.title.toLowerCase().startsWith(inputValue.toLowerCase()) // Фильтрация по названию статуса
      )}
      getOptionLabel={(option) => option.title} // Отображение названия статуса
      value={selectedStatuses} // Отображение выбранных статусов
      onChange={handleSelectionChange}
      onInputChange={handleInputChange}
      // sx={{ width: "300px" }} // Задаем ширину фильтра
      sx={{ width: "fit-content", minWidth: "150px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Фильтр по статусам"
          placeholder="Введите статус"
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.title} // Отображение названия статуса
            sx={{
              whiteSpace: "nowrap", // Текст в одну строку
              overflow: "hidden",   // Обрезка текста
              textOverflow: "ellipsis", // Многоточие, если текст не влезает
              margin: "2px", // Отступ между чипами
            }}
          />
        ))
      }
    />
  );
};

export default StatusesFilter;
