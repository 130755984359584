import React, { useState, useEffect } from "react";
import { Box, Typography, MenuItem, Menu, Button } from "@mui/material";
import moment from "moment-timezone"; 

const ClockComponent = ({ colors, theme }) => {
  const [selectedRegion, setSelectedRegion] = useState(localStorage.getItem("selectedRegion") || "Moscow");
  const [time, setTime] = useState({
    Moscow: {
      time: moment().tz("Europe/Moscow").format("HH:mm:ss"),
      date: moment().tz("Europe/Moscow").format("MM.DD.YYYY"),
    },
    Almaty: {
      time: moment().tz("Asia/Almaty").format("HH:mm:ss"),
      date: moment().tz("Asia/Almaty").format("MM.DD.YYYY"),
    },
    Kyiv: {
      time: moment().tz("Europe/Kiev").format("HH:mm:ss"),
      date: moment().tz("Europe/Kiev").format("MM.DD.YYYY"),
    },
  });
  
  const buttonColor = theme.palette.mode === "dark" ? colors.primary[500] : colors.grey[900];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
    localStorage.setItem("selectedRegion", region);
    handleClose();
  };

  const updateTime = () => {
    setTime({
      Moscow: {
        time: moment().tz("Europe/Moscow").format("HH:mm:ss"),
        date: moment().tz("Europe/Moscow").format("MM.DD.YYYY"),
      },
      Almaty: {
        time: moment().tz("Asia/Almaty").format("HH:mm:ss"),
        date: moment().tz("Asia/Almaty").format("MM.DD.YYYY"),
      },
      Kyiv: {
        time: moment().tz("Europe/Kiev").format("HH:mm:ss"),
        date: moment().tz("Europe/Kiev").format("MM.DD.YYYY"),
      },
    });
  };

  useEffect(() => {
    const interval = setInterval(updateTime, 1000); // Обновление времени каждую секунду
    return () => clearInterval(interval); // Очистка интервала при размонтировании компонента
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        bottom: 20,
        left: "50%",
        transform: "translateX(-50%)",
        bgcolor: colors.primary[400],
        border: "1px solid",
        p: 4,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: 300,
        mt: 3,
      }}
    >
      <Button
        id="demo-positioned-button"
        aria-controls={Boolean(anchorEl) ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        fullWidth
      >
        {selectedRegion === "Moscow" ? "Россия (Москва)" : selectedRegion === "Almaty" ? "Казахстан (Алматы)" : "Украина (Киев)"}
      </Button>
      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{zIndex: 99999}}
      >
        {selectedRegion !== "Moscow" && (
          <MenuItem onClick={() => handleRegionChange("Moscow")}>Россия (Москва)</MenuItem>
        )}
        {selectedRegion !== "Almaty" && (
          <MenuItem onClick={() => handleRegionChange("Almaty")}>Казахстан (Алматы)</MenuItem>
        )}
        {selectedRegion !== "Kyiv" && (
          <MenuItem onClick={() => handleRegionChange("Kyiv")}>Украина (Киев)</MenuItem>
        )}
      </Menu>

      <Box sx={{
          mt: 3,
          bgcolor: buttonColor,
          p: 1,
          borderRadius: "4px",
          textAlign: 'center'
        }}>
        <Typography variant="h4">
          {time[selectedRegion].time}
        </Typography>
        <Typography variant="body1">
          {time[selectedRegion].date}
        </Typography>
      </Box>
    </Box>
  );
};

export default ClockComponent;
