import authAxios from "./authAxios"


export const getLeads = async (page, pageSize, search, selectedBases, selectedUsers, selectedStatuses, dateCreatedStart, dateCreatedEnd, dateUpdatedStart, dateUpdatedEnd, orderBy) => {
    try {
        const response = await authAxios.get("/leads", {
            params: {
                page,
                pageSize,
                search,
                statusIds: selectedStatuses,
                userIds: selectedUsers,
                databaseIds: selectedBases,
                createdFrom: dateCreatedStart,
                createdTo: dateCreatedEnd,
                updatedFrom: dateUpdatedStart,
                updatedTo: dateUpdatedEnd,
                orderBy: orderBy
            }
        });
        
        if (response.status === 200) {
          return {
            leads: response.data.leads,
            totalCount: response.data.totalCount
          }
        } else {
            console.error("Error getting leads")
          throw new Error("Error getting leads");
        }
    } catch (error) {
        console.error(`Error getting leads:, ${error}`);
        throw error;
    }
}




export const getLeadById = async (lead_id) => {
    try {
        const response = await authAxios.get(`/leads/${lead_id}`);
        
        if (response.status === 200) {
          return response.data;
        } else if (response.status === 404) {
            return false
        } else {
            console.error("Error getting lead")
        }
    } catch (error) {
        if (error.response.status === 404) {
            return false
        } else {
            console.error(`Error getting lead:, ${error}`);
            throw error;
        }
        
    }
}


export const downloadLeads = async (params) => {
    try {
        const response = await authAxios.get("/leads/export", {
            params,
            responseType: "blob", // Ожидаем бинарные данные
        });

        if (response.status === 200) {
            // Генерируем ссылку для скачивания
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            // Извлекаем имя файла из заголовков или задаем свое
            const filename = response.headers["content-disposition"]?.split("filename=")[1]?.replace(/"/g, "") || "export.xlsx";

            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else {
            throw new Error("Ошибка при скачивании файла.");
        }
    } catch (error) {
        console.error("Ошибка скачивания файла:", error);
        throw error;
    }
};


export const changeLeadInfo = async (lead_id, newData) => {
    try {
        const response = await authAxios.patch(`/leads/${lead_id}`, newData)
        
        if (response.status === 200) {
          return response.data;
        } else {
            console.error(`Error getting leads`);
        //   throw new Error("Failed to get leads");
        }
    } catch (error) {
        console.error(`Error getting leads:, ${error}`);
        throw error;
    } 
}


// единичное удаление лидов
export const deleteLeadById = async (lead_id) => {
    try {
        const response = await authAxios.delete(`/leads/${lead_id}`);
        
        if (response.status === 200) {
            return response.status;
        } else if (response.status === 404) {
            console.error("Error while deleting lead. Lead is not found")
        //   throw new Error("Failed to get leads");
        } else {
            console.error("Error while deleting lead")
        }
    } catch (error) {
        console.error(`Error while deleting lead:, ${error}`);
        throw error;
    }
}


export const deleteLeadsList = async (leadsIds) => {
    try {
        const response = await authAxios.post(
            `/leads/delete`,
            leadsIds
        )
        
        if (response.status === 201) {
          return response.data;
        } else {
          throw new Error("Ошибка удаления лидов");
        }
    } catch (error) {
        throw new Error("Ошибка удаления лидов: " + error);
    }
}



export const changeLeadsUser = async (lead_ids, user_id) => {
    try {
        const response = await authAxios.post(
            `/leads/change-user`, 
            {
                lead_ids,
                user_id
            }
        )
        if (response.status === 201) {
            return response.data
        } else if (response.status === 404) {

            console.error("Error while changing user. One or more lead is not found")
            throw new Error("Error while changing user. One or more lead is not found")
        } else {
            console.error("Error while changing user")
            throw new Error("Error while changing user")
        }
    } catch (error) {
        console.error("Error while editing leads info: ", error)
        throw error
    }
    
}


export const deleteLeadsFile = async (fileKey) => {
    try {
        const response = await authAxios.post(
            `/leads/delete-uploaded`, 
            {
                fileKey
            }
        )
        if (response.status === 201) {
            return response.data
        } else if (response.status === 404) {

            console.error("Error while deleting file. One or more file is not found")
            throw new Error("Error while deleting file. One or more file is not found")
        } else {
            console.error("Error while deleting file")
            throw new Error("Error while deleting file")
        }
    } catch (error) {
        console.error("Error while deleting file: ", error)
        throw error
    }
    
}


export const changeLeadsStatus = async (lead_ids, status_id) => {
    try {
        const response = await authAxios.post(
            `/leads/change-leads-status`, 
            {
                lead_ids,
                status_id
            }
        )
        if (response.status === 201) {
            return response.data
        } else if (response.status === 404) {
            console.error("Error while changing status. One or more lead is not found")
            throw new Error("Error while changing status. One or more lead is not found")
        } else {
            console.error("Error while changing status")
            throw new Error("Error while changing status")
        }
    } catch (error) {
        console.error("Error while changing status: ", error)
        throw error
    }
}


export const uploadFile = async (formData) => {
    try {
        const response = await authAxios.post(
            `/leads/upload`, 
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        )
        if (response.status === 201) {
            return response.data
        } else {
            console.error("Error while uploading file")
            throw new Error("Error while uploading file")
        }
    } catch (error) {
        console.error("Error while uploading file: ", error)
        throw error
    }
}

export const confirmUpload = async (data) => {
    try {
        const response = await authAxios.post(
            `/leads/save-uploaded`, 
            data
        )
        if (response.status === 201) {
            return {
                message: response.data.message, 
                type: "success"
            }
        } 
    } catch (error) {
        console.error("Error while confirming upload: ", error)
        if (error.response.status === 400) {
            return {
                message: error.response.data.message, 
                type: "error"
            } 
        } else {
            throw error

        }
    }
}