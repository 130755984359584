import authAxios from "./authAxios";

export const getUsers = async () => {
    const response = await authAxios.get('/users')
    return response.data
}

export const createUser = async (fullName, userName, password, role) => {
    const response = await authAxios.post(
        '/users',
        {
            full_name: fullName,
            username: userName,
            password: password,
            role: role
        }
    )
    return response.data
}

export const editUser = async (newUserData, user_id) => {
    const response = await authAxios.patch(
        `/users/${user_id}`,
        newUserData
    )
    return response.data
}

export const deleteUser = async (user_id) => {
    try {
        const response = await authAxios.delete(`/users/${user_id}`);
        if (response.status === 200) {
            return true
        } else {
            throw new Error("Failed deliting user")
        }
    } catch (error) {
        console.error("Failed deliting user: ", error)
        throw error
    }
}