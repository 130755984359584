import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button } from "@mui/material";

const CommentModal = ({ open, onClose, onSave, commentData, colors }) => {
  const [formData, setFormData] = useState({
    text: "",
    createdAt: "",
    user: ""
  });

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (commentData) {
      setFormData({
        id: commentData.id || null,
        text: commentData.text || "",
      });
    } else {
      setFormData({
        id: null,
        text: "",
      });
    }
  }, [commentData]);

  const handleChange = (e) => {
    setFormData((prev) => {
      const newData = { ...prev, [e.target.name]: e.target.value };
      setIsChanged(newData.text !== commentData?.text);
      return newData;
    });
  };

  const handleSubmit = async () => {
    if (formData.text.trim()) {
      try {
        onSave(formData); 
        onClose(); 
      } catch (error) {
        console.error('Ошибка при сохранении комментария:', error);
      }
    } else {
      alert("Поле не может быть пустым"); 
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: colors.primary[400],
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          width: 400
        }}
      >
        <TextField
          label="Текст комментария"
          name="text"
          value={formData.text}
          onChange={handleChange}
          fullWidth
          multiline
        />
        <Button 
          variant="contained" 
          onClick={handleSubmit} 
          disabled={!isChanged || !formData.text.trim()}
        >
          Сохранить
        </Button>
      </Box>
    </Modal>
  );
};

export default CommentModal;
