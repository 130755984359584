import React, { useState } from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";

const UsersFilter = ({ users, selectedUsers, onChange }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue); // Обновляем значение поля ввода
  };

  const handleSelectionChange = (_, newValue) => {
    onChange(newValue.map((user) => ({id : user.id, full_name : user.full_name}))); // Передаем список выбранных `id`
    // console.log(newValue);
  };

  // console.log("users:", users);
  // console.log("selectedUsers:", selectedUsers);

  return (
    <Autocomplete
      multiple
      options={users.filter((user) =>
        user.full_name.toLowerCase().startsWith(inputValue.toLowerCase()) // Фильтрация по началу имени
      )}
      getOptionLabel={(option) => option.full_name} // Отображение имени пользователя
      value={selectedUsers} // Отображение выбранных пользователей
      onChange={handleSelectionChange}
      onInputChange={handleInputChange}
      sx={{ width: "fit-content", minWidth: "150px" }} // Задаем ширину фильтра
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Фильтр по пользователям"
          placeholder="Введите имя пользователя"
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.full_name} // Отображаем имя пользователя
            sx={{
              whiteSpace: "nowrap", // Текст в одну строку
              overflow: "hidden",   // Обрезка текста
              textOverflow: "ellipsis", // Многоточие, если текст не влезает
              margin: "2px", // Отступ между чипами
            }}
          />
        ))
      }
    />
  );
};

export default UsersFilter;
