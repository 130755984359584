import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import { UserProvider } from "./contexts/userContext"

import Topbar from "./pages/global/Topbar";

// eslint-disable-next-line
import Dashboard from "./pages/dashboard"; 
import Recordings from "./pages/recordings";
import Team from "./pages/users";
import Leads from "./pages/leads";
import Bases from "./pages/bases";
import Statuses from "./pages/statuses";
import Deposits from "./pages/deposits";
import Comments from "./pages/comments";
import Form from "./pages/form";
import Calendar from "./pages/calendar";
// eslint-disable-next-line
import FAQ from "./pages/faq";
import Login from "./pages/login";
import Expenses from "./pages/expenses";


const App = () => {
  const [theme, colorMode] = useMode();
  const location = useLocation(); 

  const isLoginPage = location.pathname === "/login"; 
  

  return (
    <UserProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <div style={{ display: 'flex', height: '100vh', width: "100vw" }}>
              <MyProSidebarProvider>
              
                <main style={{ flexGrow: 1, overflowY: 'auto', width: "100%" }}>
                    {!isLoginPage && <Topbar />}
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/" element={<Calendar />}/>
                      <Route path="/users" element={<Team />}/>
                      <Route path="/leads" element={<Leads/>} />
                      <Route path="/bases" element={<Bases />} />
                      <Route path="/statuses" element={<Statuses />} />
                      <Route path="/deposits" element={<Deposits />} />
                      <Route path="/comments" element={<Comments />} />
                      <Route path="/form/:lead_id" element={<Form />} />
                      {/* <Route path="/faq" element={<FAQ />}  /> */}
                      <Route path="/calendar" element={<Calendar />} />
                      <Route path="/recordings" element={<Recordings />} />
                      <Route path="/expenses" element={<Expenses />} />
                      <Route path="/login" element={<Login  />} />
                    </Routes>
                </main>
              </MyProSidebarProvider>
            </div>
            
          
        </ThemeProvider>
      </ColorModeContext.Provider>
      </UserProvider>
  );
};

export default App;
