import { useState, useEffect } from "react";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";

// 

const LeadsButtonToolbar = ({ onDelete, selectedCount, onPickChange, users, statuses, onStatusChange, onOpenModal, onUpdate, no_delete = false, no_button = false, onExport, isExportDisabled = false  }) => {    
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);  // Для управления открытием меню пользователей
  const [anchorElStatusMenu, setAnchorElStatusMenu] = useState(null); // Для управления открытием меню статусов
  const [isUpdatedOn, setIsUpdatedOn] = useState(
    localStorage.getItem("isUpdatedOn") || false
  );

  const handleUpdatedOn = () => {
    setIsUpdatedOn(!isUpdatedOn);
    localStorage.setItem("isUpdatedOn", !isUpdatedOn);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElUserMenu(null);
    setAnchorElStatusMenu(null);
  };

  const handlePickChange = (user_id) => {
    onPickChange(user_id);
    handleMenuClose();
  };

  const handleStatusChange = (status_id) => {
    onStatusChange(status_id);
    handleMenuClose();
  };

  useEffect(() => {
    if (!isUpdatedOn) return;

    const interval = setInterval(() => {
      onUpdate();
    }, 15000);

    return () => clearInterval(interval);
  }, [isUpdatedOn, onUpdate]);

  return (
    <GridToolbarContainer>
      <Box display="flex" width="100%" justifyContent="space-between" flexWrap={"nowrap"} marginBottom={"8px"}>
        <Box display="flex" width="100%">
          <GridToolbarQuickFilter />
        </Box>
        <Box display="flex" gap="8px">
          
          {!no_button && 
          <>
          <Box gap="8px" display="flex" flexDirection={"column"} alignItems={"center"}>
            <Typography variant="body2">Автообновление</Typography>
            <Switch
              checked={isUpdatedOn}
              onChange={handleUpdatedOn}
            />
          </Box>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenUserMenu}
            disabled={selectedCount === 0}
          >
            Привязать к
          </Button>
          <Menu anchorEl={anchorElUserMenu} open={Boolean(anchorElUserMenu)} onClose={handleMenuClose}>
            {users && users.length === 0 ? (
              <MenuItem disabled>Нет доступных пользователей</MenuItem>
            ) : users && users.length === 1 ? (
              <MenuItem onClick={() => handlePickChange(users[0]?.id)}>
                {users[0]?.full_name}
              </MenuItem>
            ) : users ? (
              users.map((user) => (
                <MenuItem key={user.id} onClick={() => handlePickChange(user.id)}>
                  {user.full_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Загрузка пользователей...</MenuItem> // Вариант отображения во время загрузки
            )}
          </Menu>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => setAnchorElStatusMenu(event.currentTarget)} // Открытие меню статусов
            disabled={selectedCount === 0} // Кнопка активна только при выбранном лиде
          >
            Сменить статус
          </Button>
          <Menu anchorEl={anchorElStatusMenu} open={Boolean(anchorElStatusMenu)} onClose={handleMenuClose}>
            {statuses && statuses.length === 0 ? (
              <MenuItem disabled>Нет доступных статусов</MenuItem>
            ) : statuses ? (
              statuses.map((status) => (
                <MenuItem key={status.id} onClick={() => handleStatusChange(status.id)}>
                  {status.title} {/* Или любое другое поле для отображения названия статуса */}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Загрузка статусов...</MenuItem> // Вариант отображения во время загрузки
            )}
          </Menu>

          {!no_delete && <Button
            variant="contained"
            color="error"
            onClick={onDelete}
            disabled={selectedCount === 0}
          >
            Удалить
          </Button>}
          <Button
            variant="contained"
            color="blue"
            onClick={onOpenModal}
          >
            Загрузить с файла
          </Button>
          <Button
            variant="contained"
            color="blue"
            onClick={onExport}
            disabled={isExportDisabled}
          >
            Выгрузить в файл
          </Button>
          </>}
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

export default LeadsButtonToolbar;
