import authAxios from "./authAxios";


export const getRecordings = async (page, pageSize, search) => {
    try {
        const response = await authAxios.get("/recordings", {
            params: {
                page,
                pageSize,
                search
            }
        });
        
        if (response.status === 200) {
          return {
            recordings: response.data.recordings,
            totalCount: response.data.totalCount
          }
        } else {
            console.error("Error getting recordings")
            throw new Error("Error getting leads");
        }
    } catch (error) {
        console.error(`Error getting recordings:, ${error}`);
        throw error;
    }
}

export const createRecording = async (audioFile, title, description, lead_id) => {
    const formData = new FormData();
    formData.append("file", audioFile);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("lead_id", lead_id);

    try {
        const response = await authAxios.post("/recordings", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        
        if (response.status === 201) {
            return response.data.data;
        } else {
            throw new Error("Ошибка при создании записи");
        }
    } catch (error) {
        console.error("Ошибка при создании записи:", error);
        throw error;
    }
};




export const deleteRecording = async (recordingsId) => {
    try {
        const response = await authAxios.delete(`/recordings/${recordingsId}`)
        if (response.status === 200) {
            return response.data
        } else if (response.status === 404) {
            throw new Error("Error while deleting recordings. Recordins is not found")
        } else {
            throw new Error("Error while deleting recordings")
        }
    } catch (error) {
        console.error("Error while deleting recordings: ", error)
        throw error
    }
}

export const editRecording = async (recordId, updatedData) => {
    try {
        const response = await authAxios.put(`/recordings/${recordId}`, updatedData);
        return response.data;
    } catch (error) {
        console.error("Ошибка при обновлении записи:", error);
        throw error;
    }
};