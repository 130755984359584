import authAxios from "./authAxios";

export const getExpenses = async (page, pageSize, search) => {
    try {
        const response = await authAxios.get("/expenses", {
            params: {
                page,
                pageSize,
                search
            }
        });
        
        if (response.status === 200) {
          return {
            expenses: response.data.expenses,
            totalCount: response.data.totalCount
          }
        } else {
            console.error("Error getting expenses")
            throw new Error("Error getting expenses");
        }
    } catch (error) {
        console.error(`Error getting expenses:, ${error}`);
        throw error;
    }
}


export const createExpense = async (newExpense) => {
    try {
        const response = await authAxios.post(
            "/expenses",
            newExpense
        )
        if (response.status === 201) {
            return response.data
        } else if (response.status === 400) {
            console.error("Error while creating expense. Invalid request")
        } else {
            console.error("Error while creating expense")
        }
    } catch (error) {
        console.error("Error while creating expense: ", error)
        throw error
    }
}



export const editExpense = async (editedExpense, expenseId) => {
    try {
        const response = await authAxios.put(
            `/expenses/${expenseId}`,
            editedExpense
        )
        if (response.status === 200) {
            return response.data
        } else if (response.status === 404) {
            console.error("Error while editing expense. Expense is not found")
        } else {
            console.error("Error while editing expense")
        }
    } catch (error) {
        console.error("Error while editing expense: ", error)
    }
}


export const deleteExpense = async (expenseId) => {
    try {
        const response = await authAxios.delete(`/expenses/${expenseId}`)
        if (response.status === 200) {
            return true
        } else if (response.status === 404) {
            console.error("Error while deleting expense. Expense is not found")
        } else {
            console.error("Error while deleting expense")
        }
    } catch (error) {
        console.error("Error while deleting expense: ", error)
    } 
} 