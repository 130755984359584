import { useState, useContext, useRef, useMemo, useEffect, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import Grid from "@mui/material/Grid2";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  Box,
  List,
  ListItem,
  Typography,
  Pagination,
  useTheme,
  Modal,
  Button,
  TextField,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import moment from 'moment';
import ruLocale from "@fullcalendar/core/locales/ru";
import UserContext from "../../contexts/userContext";
import { createEvent, deleteEvent } from "../../services/calendarService";
import DeleteConfirmationModal from "../../modals/DeleteConfirmationModal";
import { titleConfig } from "../../data/config";

const Calendar = () => {


  
  const theme = useTheme();
  const calendarRef = useRef(null);
  const { events, addNewEvent, removeEvent, showAlert, currentFilter, setCurrentFilter } = useContext(UserContext);
  const [filteredEvents, setFilteredEvents] = useState(events);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 10;
  const colors = tokens(theme.palette.mode);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Состояние для модалки удаления
  const [eventToDelete, setEventToDelete] = useState(null); // Событие для удаления
  const [newEvent, setNewEvent] = useState({
    id: null,
    title: "",
    description: "",
    time: null, 
  });

  const isFormValid = newEvent.title && newEvent.time; // Проверяем, что название и дата заполнены
  const isFormChanged = (newEvent.title !== "" || newEvent.time !== null || newEvent.description !== ""); // Проверка на изменения


  useEffect(() => {
    document.title = titleConfig.CALENDAR;  // Устанавливаем заголовок страницы
  }, []);

  const filterEvents = useCallback((filter) => {
    const now = moment();
    let filtered;

    switch (filter) {
      case "день":
        filtered = events.filter(event => 
          moment(event.event_start_time).isBetween(now, now.clone().add(1, "day"), null, '[]')
        );
        break;
      case "неделю":
        filtered = events.filter(event => 
          moment(event.event_start_time).isBetween(now, now.clone().add(7, "days"), null, '[]')
        );
        break;
      case "месяц":
        filtered = events.filter(event => 
          moment(event.event_start_time).isBetween(now, now.clone().add(30, "days"), null, '[]')
        );
        break;
      default:
        filtered = events;
        break;
    }

    setFilteredEvents(filtered);
    setCurrentPage(1); // Сброс на первую страницу при новом фильтре
  }, [events]);

  // Пагинация
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const paginatedEvents = useMemo(() => {
    return filteredEvents.slice((currentPage - 1) * eventsPerPage, currentPage * eventsPerPage);
  }, [filteredEvents, currentPage, eventsPerPage]);

  const handleDateChange = (newValue) => {
    setNewEvent((prev) => ({
      ...prev,
      time: newValue,
    }));
  };

  const handleDateClick = (selected) => {
    const selectedDate = selected.start;
    
    setNewEvent((prev) => ({
      ...prev,
      time: moment(selectedDate), 
    }));
    setOpenModal(true);
  };

  const handleEventSubmit = async () => {
      const eventStart = newEvent.time.toDate();
      
      const newEventData = {
        title: newEvent.title,
        description: newEvent.description,
        start: eventStart,
      };
      
      setNewEvent({ title: "", description: "", time: null });
      setOpenModal(false);
      
      try {
        const createdEvent = await createEvent(newEventData.title, newEventData.start, newEventData.description);
        if (createdEvent) {
          addNewEvent(createdEvent);
          showAlert("Событие успешно добавлено!", "success"); // Уведомление при успешном создании
        } else {
          throw new Error("Failed to create event");
        }
      } catch (error) {
        console.error("Ошибка при создании события:", error);
        showAlert("Ошибка при создании события: " + error.message, "error");
      }
      
      // Очищаем форму
      setNewEvent({ title: "", description: "", time: null });
      setOpenModal(false);
  };

  const handleEventClick = (selected) => {
    setEventToDelete(selected.event); // Запоминаем событие для удаления
    setOpenDeleteModal(true); // Открываем модалку подтверждения удаления
  };

  const handleDeleteEvent = async () => {
    if (eventToDelete) {
      try {
        const eventId = eventToDelete.id;
        const deleted = await deleteEvent(eventId); 
        if (deleted) {
          removeEvent(eventId); // Удаляем событие из состояния контекста
          setFilteredEvents((prev) => prev.filter(event => String(event.id) !== String(eventId)));
          showAlert("Событие успешно удалено.", "success"); // Уведомление при успешном удалении
        }
      } catch (error) {
        console.error("Ошибка при удалении события:", error);
        showAlert("Не удалось удалить событие. Пожалуйста, попробуйте еще раз.", "error"); // Уведомление об ошибке
      }
      setOpenDeleteModal(false); // Закрываем модалку после удаления
    }
  };

  useEffect(() => {
    filterEvents(currentFilter);
  }, [currentFilter, filterEvents]);

  return (
    <Box m="20px" sx={{ position: "relative" }}>
      <Header title="Календарь" subtitle="Календарь событий" />
      <Grid container spacing={1}>
        <Grid xs={12} md={3}>
          <Box backgroundColor={colors.primary[400]} p="15px" borderRadius="4px" fullWidth>
            <Typography variant="h5">События</Typography>
            <Box display="flex" gap="10px" mb="20px">
              {["день", "неделю", "месяц", "все"].map((filter) => (
                <Button 
                  key={filter} 
                  variant="outlined" 
                  onClick={async () => await setCurrentFilter(filter)} 
                  sx={{
                    backgroundColor: currentFilter === filter ? colors.primary[300] : colors.primary[400],
                    color: currentFilter === filter ? "white" : colors.grey[500],
                  }}
                  title={filter === "все" ? "Все" : `На ${filter}`}
                >
                  {filter === "все" ? "Все" : `На ${filter}`}
                </Button>
              ))}
            </Box>
            <List>
              {paginatedEvents.map(event => (
                <ListItem 
                  key={event.id} 
                  sx={{ borderBottom: "1px solid gray", paddingBottom: "15px", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <Typography variant="h4" noWrap sx={{ width: '100%', textOverflow: 'ellipsis' }}>
                    {event.title}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ marginBottom: "8px" }}>
                    {moment(event.event_start_time).format("HH:mm, DD.MM.YYYY")}
                  </Typography>
                  {event.description && (
                    <Typography variant="body1" sx={{ marginTop: "8px" }}>
                      {event.description}
                    </Typography>
                  )}
                </ListItem>
              ))}
            </List>

            {filteredEvents.length > eventsPerPage && (
              <Pagination
                count={Math.ceil(filteredEvents.length / eventsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            )}
          </Box>
        </Grid>
        <Grid xs={12} md={8}>
          <Box ml="15px" fullWidth>
            <FullCalendar
              height="75vh"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
              }}
              locale={ruLocale}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              ref={calendarRef}
              select={handleDateClick}
              eventClick={handleEventClick}
              events={events.map(event => ({
                id: event.id,
                title: event.title,
                start: event.event_start_time,
              }))}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Модалка для создания события */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          bgcolor={colors.primary[400]}
          borderRadius="8px"
          sx={{ width: 300, position: "absolute", top: '50%', left: '50%', zIndex: 20000, transform: 'translate(-50%, -50%)', borderRadius: "8px", boxShadow: 24, p: 4 }}
        >
          <Typography color="white" variant="h6" gutterBottom>
            Создать событие
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TextField
              label="Название события"
              fullWidth
              value={newEvent.title}
              onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Описание"
              fullWidth
              value={newEvent.description}
              onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
              sx={{ mb: 2 }}
            />
            <DateTimePicker
              label="Выберите дату и время"
              value={newEvent.time}
              format="DD.MM.YYYY HH:mm"
              ampm={false}
              onChange={handleDateChange}
              sx={{ mb: 2 }}
              slotProps={{ textField: { variant: 'outlined', fullWidth: true} }}
            />
          </LocalizationProvider>
          <Button variant="contained" onClick={handleEventSubmit} disabled={!isFormValid || !isFormChanged}>
            Добавить событие
          </Button>
        </Box>
      </Modal>

      {/* Модалка для удаления события */}
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteEvent}
        colors={colors}
        text={`Вы уверены, что хотите удалить событие '${eventToDelete ? eventToDelete.title : ""}'?`}
      />
    </Box>
  );
};

export default Calendar;
