import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, MenuItem, Select, Chip, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { getLeadById } from '../../services/leadsService';
import { createDeposit, editDeposit } from '../../services/depositService';
import { getStatuses } from '../../services/statusService';
import { changeLeadInfo } from '../../services/leadsService';
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../contexts/authContext";
import UserContext from "../../contexts/userContext";
import { useNavigate } from "react-router-dom";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import moment from 'moment';
import CommentsAccordion from "./modules/CommentsAccordion";
import RecordingAccordion from "./modules/RecordingAccordion";

const LeadForm = () => {
    const { lead_id } = useParams();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [lead, setLead] = useState(null);
    const [deposits, setDeposits] = useState([]);
    const [comments, setComments] = useState([]);
    const [records, setRecords] = useState([]);
    const [statuses, setStatuses] = useState([]); 
    const [selectedStatusId, setSelectedStatusId] = useState(null);
    
    const [newDepositAmount, setNewDepositAmount] = useState("");
    const [isAddingDeposit, setIsAddingDeposit] = useState(false);
    const [editableDepositIndex, setEditableDepositIndex] = useState(null);
    const [editableDepositAmount, setEditableDepositAmount] = useState("");
    const [isEditing, setIsEditing] = useState({ full_name: false, email: false, additional_info: false });
    const [editableFields, setEditableFields] = useState({ full_name: "", email: "", additional_info: "" });
    const { user } = useContext(AuthContext);
    const { showAlert } = useContext(UserContext);
    const [blockBackground, setBackgroundColor] = useState(colors.primary[500]);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();
    const [me, setMe] = useState(null);
    const [title, setTitle] = useState("HKEX-CRM")
    const [recordings, setRecordings] = useState([]);
    const [user_comments, setUserComments] = useState([]);
    
    // const [leadsName, setLeadsName] = useState(null)

    const statusMapping = {
        'ftd': 1,
        'reload': 2,
        'deposit': 3
    };

    useEffect(() => {
        if (user) {
          setMe(user);
        }
      }, [user]);

      useEffect(() => {
        if (comments) {
            setUserComments(comments);
        }
      }, [comments]);

    useEffect(() => {
        if (records) {
            setRecordings(records);
        }
    }, [records]);

      
      useEffect(() => {
        document.title = `${title} | HKEX-CRM`
      }, [title]);


    useEffect(() => {
        const calculateTotal = () => {
            const total = deposits.reduce((acc, deposit) => acc + deposit.amount, 0);
            setTotal(total); 
        }
        calculateTotal();
    }, [deposits]);

    useEffect(() => {
        if (theme.palette.mode === 'dark') {
          setBackgroundColor(colors.primary[500]);
        } else {
          setBackgroundColor(colors.grey[900]);
        }
      }, [theme.palette.mode]);

    useEffect(() => {
        const fetchLead = async () => {
            try {
                const leadData = await getLeadById(lead_id);
                if (!leadData) {
                    navigate("/leads");
                }
                setLead(leadData);
                setDeposits(leadData.deposits || []);
                setComments(leadData.notes || []);
                setRecords(leadData.recordings || []);
                setEditableFields({
                    full_name: leadData.full_name,
                    email: leadData.email || "",
                    additional_info: leadData.additional_info || ""
                });
                setTitle(leadData.full_name)
                setSelectedStatusId(leadData.status_id)
            } catch (error) {
                console.error(error);
            }
        };
        const fetchStatuses = async () => {
            try {
                const statusData = await getStatuses();
                setStatuses(statusData);
            } catch (error) {
                console.error("Error fetching statuses: ", error);
            }
        };

        fetchLead();
        fetchStatuses();
    }, [lead_id]);

    const handleCopyPhoneNumber = () => {
        if (lead.phone) {
            navigator.clipboard.writeText(lead.phone)
                .then(() => showAlert("Номер телефона скопирован!", "success"))
                .catch((error) => showAlert(`Ошибка при копировании: ${error}`, "error"));
        }
    };

    const handleFieldEdit = (field) => {
        setIsEditing((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleStatusChange = (event) => {
        setSelectedStatusId(event.target.value); // Update selected status
    };

    const handleInputChange = (field, value) => {
        setEditableFields((prev) => ({ ...prev, [field]: value }));
    };

    const handleAddDeposit = async () => {
        if (newDepositAmount.trim()) {
            const depositData = {
                amount: parseFloat(newDepositAmount),
                lead_id: lead_id,
            };

            try {
                const createdDeposit = await createDeposit(depositData);
                if (createdDeposit.deposit_status_id === 1) {
                    try {
                        const curentLead = await getLeadById(lead_id);
                        if (!curentLead) {
                            navigate("/leads");
                        }
                    } catch (error) {
                        console.error("Ошибка при обновлении лида:", error);
                    }
                }
                showAlert("Депозит успешно добавлен!", "success");
                setDeposits((prevDeposits) => [...prevDeposits, createdDeposit]);
                setNewDepositAmount("");
                setIsAddingDeposit(false);
            } catch (error) {
                console.error("Ошибка при добавлении депозита:", error);
            }
        }
    };

    const handleEditDeposit = (index) => {
        setEditableDepositIndex(index);
        setEditableDepositAmount(deposits[index].amount);
    };

    const handleSaveEditDeposit = async (index) => {
        const updatedDepositData = {
            amount: parseFloat(editableDepositAmount),
        };

        try {
            const updatedDeposit = await editDeposit(updatedDepositData, deposits[editableDepositIndex].id);
            const updatedDeposits = [...deposits];
            updatedDeposits[editableDepositIndex] = updatedDeposit;
            setDeposits(updatedDeposits);
            setEditableDepositIndex(null);
            setEditableDepositAmount("");
            showAlert("Депозит успешно отредактирован!", "success");
        } catch (error) {
            console.error("Ошибка при редактировании депозита:", error);
        }
    };


    const handleReturn = () => {
        navigate(-1);
    }

    const handleSave = async () => {
        const updatedData = {};

        if (editableFields.full_name && editableFields.full_name !== lead.full_name) {
            updatedData.full_name = editableFields.full_name;
        }
        
        if (editableFields.email && editableFields.email !== lead.email) {
            updatedData.email = editableFields.email;
        }
        
        if (editableFields.additional_info && editableFields.additional_info !== lead.additional_info) {
            updatedData.additional_info = editableFields.additional_info;
        }
        
        if (selectedStatusId && selectedStatusId !== lead.status_id) {
            updatedData.status_id = selectedStatusId;
        }
        if (Object.keys(updatedData).length > 0) {
            try {
                await changeLeadInfo(lead_id, updatedData);
                // console.log("Saved data:", updatedData);
            } catch (error) {
                console.error("Error saving lead info: ", error);
            }
            showAlert("Данные успешно сохранены!", "success");
        } else {
            console.log("No changes detected, nothing to save.");
        }
    };

    if (!lead) {
        return <div>Загрузка...</div>;
    }

    return (
        <Box m="20px">
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="20px">
                <Box
                    sx={{
                        backgroundColor: theme.palette.mode === 'dark' ? colors.primary[600] : colors.primary[900],
                        p: 4,
                        borderRadius: "8px",
                        mb: 2,
                    }}
                >
                    {[
                        { label: "Полное имя", key: "full_name", isEditable: true },
                        { label: "Email", key: "email", isEditable: true },
                        { label: "Номер телефона", key: "phone", value: lead.phone || "Нет телефона", icon: true },
                        { label: "Код страны", key: "country_code", value: lead.country_code || "Нет кода страны" },
                        { label: "База данных", key: "databaseTitle", value: lead.databaseTitle || "Нет названия базы" },
                        { label: "Дата создания", key: "createdAt", value: lead.createdAt ? moment(lead.createdAt).format("HH:mm:ss DD.MM.YYYY") : "Нет даты создания" },
                        { label: "Дата обновления", key: "updatedAt", value: lead.updatedAt ? moment(lead.updatedAt).format("HH:mm:ss DD.MM.YYYY") : "Нет даты обновления" },
                        { label: "Тотал", key: "total", value: total && `${total} $` },
                        { label: "Доп. инфо", key: "additional_info", isEditable: true, multiline: true },
                    ].map(({ label, key, isEditable, value, multiline, icon }) => (
                        <Box
                            key={key}
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "min-content auto min-content",
                                alignItems: "center",
                                columnGap: "16px",
                                mb: 3,
                            }}
                        >
                            {/* Заголовок */}
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "1rem", // Чуть меньше заголовки
                                }}
                            >
                                {label}:
                            </Typography>

                            {/* Поле или текст */}
                            <Box>
                                {isEditable ? (
                                    isEditing[key] ? (
                                        <TextField
                                            fullWidth
                                            multiline={multiline}
                                            rows={multiline ? 3 : 1}
                                            value={editableFields[key]}
                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                            InputProps={{
                                                style: { fontSize: "1rem" }, // Уменьшенный шрифт в поле
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                                            {editableFields[key]}
                                        </Typography>
                                    )
                                ) : (
                                    <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                                        {value}
                                    </Typography>
                                )}
                            </Box>

                            {icon && key === "phone" && (
                                <IconButton onClick={handleCopyPhoneNumber}>
                                    <CopyAllIcon fontSize="medium" />
                                </IconButton>
                            )}

                            {/* Иконка редактирования */}
                            {isEditable && (
                                <IconButton onClick={() => handleFieldEdit(key)}>
                                    <EditIcon fontSize="medium" /> {/* Уменьшаем размер иконки */}
                                </IconButton>
                            )}
                        </Box>
                    ))}
                </Box>

                {/* Deposits Section */}
                <Box>
                    <Typography variant="h6" marginTop={2}>Депозиты</Typography>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                        {deposits.map((deposit, index) => (
                            <Box key={index} display="flex" alignItems="center" marginRight={1}>
                                {editableDepositIndex === index ? (
                                    <>
                                        <TextField
                                            value={editableDepositAmount}
                                            onChange={(e) => setEditableDepositAmount(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                        />
                                        <Button onClick={() => handleSaveEditDeposit(index)} variant="contained" size="small">Сохранить</Button>
                                    </>
                                ) : (
                                    <>
                                        <Chip
                                            label={`$${deposit.amount} (${Object.keys(statusMapping).find(key => statusMapping[key] === deposit.deposit_status_id)})`}
                                            sx={{
                                                margin: '5px',
                                                backgroundColor: deposit.deposit_status_id === statusMapping['ftd'] 
                                                    ? colors.blueAccent[500] // Color for 'ftd'
                                                    : deposit.deposit_status_id === statusMapping['reload'] 
                                                        ? colors.redAccent[500] // Color for 'reload'
                                                        : colors.greenAccent[500], // Color for 'deposit'
                                                color: colors.grey[100]
                                            }}
                                            onClick={() => handleEditDeposit(index)}
                                            // onDelete={() => handleDeleteDeposit(index)} // Uncomment if you want delete functionality
                                        />
                                    </>
                                )}
                            </Box>
                        ))}
                        {!isAddingDeposit ? (
                            <IconButton
                                sx={{ color: colors.blueAccent[400], margin: '5px' }}
                                onClick={() => setIsAddingDeposit(true)}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        ) : (
                            <>
                                <TextField
                                    value={newDepositAmount}
                                    onChange={(e) => setNewDepositAmount(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    inputMode="numeric"
                                    placeholder="Введите сумму"
                                />
                                <Button onClick={handleAddDeposit} variant="contained" size="small">Добавить</Button>
                            </>
                        )}
                    </Box>
                    <Typography><strong>Статус:</strong><br/> 
                            <Select
                                value={selectedStatusId || ""} // Use selected status id
                                onChange={handleStatusChange}
                                variant="outlined"
                            >
                                {statuses.map((status) => (
                                    <MenuItem key={status.id} value={status.id}>
                                        {status.title}
                                    </MenuItem>
                                ))}
                            </Select>
                    </Typography>
                    <Box display="flex" flexDirection="row" gap={2} maxWidth="200px">
                    <Button onClick={handleSave} variant="contained" sx={{ mt: 4, backgroundColor: colors.blueAccent[500], color: colors.grey[100] }}>
                        Сохранить
                    </Button>
                    <Button onClick={handleReturn} variant="contained" sx={{ mt: 4, backgroundColor: colors.redAccent[500], color: colors.grey[100] }}>
                        Вернуться назад
                    </Button>
                    </Box>
                </Box>
            </Box>
            <CommentsAccordion comments={user_comments} setComments={setUserComments} me={me} lead_id={lead_id} showAlert={showAlert} blockBackground={blockBackground} colors={colors} theme={theme} />
            <RecordingAccordion records={recordings} colors={colors} theme={theme} lead_id={lead_id} setRecords={setRecords} showAlert={showAlert}/>
        </Box>
    );
};

export default LeadForm;
